import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class CryptoService {

  constructor(private api: ApiService) { }

  getCryptoCurrencies(query?: any) {
    return this.api.get(this.api.path + '/coinspaid/accounts', query);
  }
  getCurrencies(query?: any) {
    return this.api.get(this.api.path + '/cryptocurrency', query);
  }
  insertCurrency(data: any) {
    return this.api.post(this.api.path + '/cryptocurrency', data);
  }
  updateCryptoCurrency(id: any, data: any) {
    return this.api.post(this.api.path + '/cryptocurrency/' + id, data);
  }

  getCryptoCurrenciesResller(resellerId) {
    return this.api.get(this.api.path + '/coinspaid/accounts/' + resellerId);
  }
}
