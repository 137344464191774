import { ModuleWithProviders, NgModule } from '@angular/core';
import {
    ApiService,
    BannerService,
    BonusService, CasinoService,
    CountryService,
    CouponService,
    CurrencyService,
    EventService, FilialeService, GameService, LogService,
    MarketService,
    MediaService,
    MembershipService,
    OperatorService, OptionService, PaymentService,
    PermissionService, PromotionService,
    ResellerService,
    RoleService,
    SessionService, StatisticsService,
    SystemConfigService, TerminalService,
    TransactionService,
    CardService,
    ReceiptService,
    SupportMessageService,
    EmailsService,
    CashLogsService,
    CryptoService,
    PaymentGatewayService,
    PageService,
    FooterService,
    EmployeeService

} from './services';

const SERVICES = [
    ApiService,
    OperatorService,
    SessionService,
    ResellerService,
    RoleService,
    PermissionService,
    FilialeService,
    TerminalService,
    TransactionService,
    CountryService,
    CurrencyService,
    MembershipService,
    MarketService,
    BannerService,
    BonusService,
    EventService,
    MediaService,
    StatisticsService,
    SystemConfigService,
    LogService,
    CouponService,
    PaymentService,
    GameService,
    CasinoService,
    OptionService,
    PromotionService,
    CardService,
    ReceiptService,
    SupportMessageService,
    EmailsService,
    CashLogsService,
    CryptoService,
    PaymentGatewayService,
    PageService,
    FooterService,
    EmployeeService
];

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: []
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [...SERVICES]
        };
    }
}
