import { Component, ContentChildren, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { Header } from 'primeng/api';

@Component({
    selector   : 'p-dialog-custom',
    templateUrl: './p-dialog-custom.html',
})

export class PDialogCustomComponent implements OnInit, OnDestroy {
    @ViewChild('el') el;
    _visible: any;

    get visible() {
        return this._visible;
    }

    @Input('visible')
    set visible(value) {
        this._visible = value;

        if (value) {
            this.bodyLockEnable();
        } else {
            if (this.el) {
                enableBodyScroll(this.el.nativeElement);
            }
        }
    }

    @Output() visibleChange: EventEmitter<any> = new EventEmitter();
    @Input() modal;
    @Input() appendTo;
    @Input() blockScroll;
    @Input() style;
    @Input() contentStyle;
    @ContentChildren(Header, {descendants: false}) headerFacet: QueryList<Header>;
    id;

    constructor() {
        this.id = 'modal' + Math.random();

    }

    ngOnInit() {

    }

    close($event: any) {
        this.visibleChange.emit($event);
        enableBodyScroll(this.el.nativeElement);
    }

    private bodyLockEnable() {
        setTimeout(() => {
            disableBodyScroll(this.el.nativeElement, {
                allowTouchMove: (el) => {
                    while (el && el !== document.body) {
                        if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                            return true;
                        }

                        el = el.parentNode;
                    }
                }
            });
        }, 150);

    }

    ngOnDestroy() {
        clearAllBodyScrollLocks();
    }
}
