export class UserWallet {
    walletTypes = {1: 'real', 2: 'bonus'};
    walletType: any;

    blockedBalance: any;
    currencyId: any;
    currentBalance: any;
    id: any;
    userId: any;
    walletTypeId: any;
    status: any;
    amount: any = 0;
    operation: any;

    constructor(data?: {}) {
        Object.assign(this, data);
    }

}
