import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-field-message',
    templateUrl: 'field-message.component.html'
})

export class FieldMessageComponent implements OnInit {
    public _control: FormControl;

    @Input() set controlName(value) {
        if (value) {
            this._control = value;
            this._control.valueChanges.subscribe((val) => this.valueChanged(val));
        }
    }

    @Input() message: string = '';

    constructor(public translate: TranslateService) {
    }

    ngOnInit() {

    }

    private valueChanged(value?) {

        const errors = this._control.errors || {};
        const error = Object.keys(errors)[0];
        switch (error) {
            case 'required':
                this._control.errors['message'] = 'Bu alan zorunludur';
                break;
            case 'minlength':
                this._control.errors['message'] = 'En az ' + this._control.errors[error].requiredLength + ' karakter gerekli';
                break;
            case 'maxlength':
                this._control.errors['message'] = 'En fazla ' + this._control.errors[error].requiredLength + ' karakter gerekli';
                break;
            case 'customMin':
                this._control.errors['message'] = 'En az ' + this._control.errors[error].min + ' olabilir';
                break;
            case 'customMax':
                this._control.errors['message'] = 'En fazla ' + this._control.errors[error].max + ' olabilir';
                break;
            case 'pattern':
                this._control.errors['message'] = 'Password Validator Regex';
                break;
            default:
                if (this._control.errors) {
                    this._control.errors['message'] = 'Bu alan zorunludur';
                }
                break;
        }

    }
}
