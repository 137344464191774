

<ng-container *ngFor="let menuItem of model" >
  <li *ngIf="menuItem.visible !== false"  [ngClass]="{'has-submenu': menuItem.items?.length,'show1': menuItem.expanded}"  (click)="toggleSubMenu(menuItem)">
    <ng-container *ngIf="menuItem.visible !== false">
    <a *ngIf="!menuItem.items" [routerLink]="menuItem.routerLink" routerLinkActive="active"  (click)="isMobileDevice() ? redirectAndReload(menuItem.routerLink) :onHideSidebar()">
      <span class="icon" [ngClass]="{'show-icon': !sidebarActive}"><i class="{{menuItem.icon}}"></i></span>
      <span class="title" [ngClass]="{'block': sidebarActive, 'none': !sidebarActive}">{{menuItem.label | translate}}</span>
    </a>
  </ng-container>

    <ng-container *ngIf="menuItem.visible !== false">
    <a *ngIf="menuItem.items && menuItem.visible !== false" class="has-submenu" >
      <span class="icon" [ngClass]="{'show-icon': !sidebarActive}"><i class="{{menuItem.icon}}"></i></span>
      <span class="title" [ngClass]="{'block': sidebarActive, 'none': !sidebarActive}">{{menuItem.label | translate}}</span>
    </a>
    <ul *ngIf="menuItem.items" class="submenu">
      <li *ngFor="let submenuItem of menuItem.items" [ngClass]="{'has-submenu-1': submenuItem.items?.length,'show2': submenuItem.expanded}" (click)="toggleSubMenu2(submenuItem); $event.stopPropagation()">
        <ng-container *ngIf="submenuItem.visible !== false">
        <a *ngIf="!submenuItem.items  && submenuItem.visible !== false" [routerLink]="submenuItem.routerLink" routerLinkActive="active"  (click)="isMobileDevice() ? redirectAndReload(submenuItem.routerLink) : onHideSidebar()">
          <span class="icon" [ngClass]="{'show-icon': !sidebarActive}"><i class="{{submenuItem.icon}}"></i></span>
          <span class="title subtitle" [ngClass]="{'block': sidebarActive, 'none': !sidebarActive}">{{submenuItem.label | translate}}</span>
        </a>
        <a *ngIf="submenuItem.items && submenuItem.visible !== false" class="has-submenu-1">
          <span class="icon" [ngClass]="{'show-icon': !sidebarActive}"><i class="{{submenuItem.icon}}"></i></span>
          <span class="title subtitle" [ngClass]="{'block': sidebarActive, 'none': !sidebarActive}">{{submenuItem.label | translate}}</span>
        </a>
        <ul *ngIf="submenuItem.items && submenuItem.visible !== false" class="submenu-1" >
          <li *ngFor="let secondSubmenuItem of submenuItem.items"  (click)="isMobileDevice() ? redirectAndReload(secondSubmenuItem.routerLink) :onHideSidebar()">
            <a *ngIf="secondSubmenuItem.visible !== false" [routerLink]="secondSubmenuItem.routerLink" routerLinkActive="active">
              <span class="icon" [ngClass]="{'show-icon': !sidebarActive}"><i class="{{secondSubmenuItem.icon}}"></i></span>
              <span class="title subtitle" [ngClass]="{'block': sidebarActive, 'none': !sidebarActive}">{{secondSubmenuItem.label | translate}}</span>
            </a>
          </li>
        </ul>
      </ng-container>
      </li>
    </ul>
  </ng-container>
  </li>
</ng-container>