import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class EmailsService {

  constructor(private api: ApiService) { }

      getSystemTemplates(query?: any) {
        return this.api.get(this.api.path + '/systemTemplate', query)
      }
      addSystemTemplates(data: any) {
        return this.api.post(this.api.path + '/systemTemplate', data)
      }
      
      deleteSystemTemplate(id: any) {
        return this.api.delete(this.api.path + '/systemTemplate/' + id);
      }
      updateSystemTemplate(id: any, data: any) {
        return this.api.post(this.api.path + '/systemTemplate/' + id, data);
      }
      getTemplatesTags(query?: any) {
        return this.api.get(this.api.path + '/tag', query)
      }
      insertTemplatesTags(query?: any) {
        return this.api.post(this.api.path + '/tag', query);
      }
      updateTemplatesTags(id: any, data: any) {
        return this.api.post(this.api.path + '/tag/' + id, data);
      }
    
    
      deleteTemplatesTags(id: any) {
        return this.api.delete(this.api.path + '/tag/' + id);
      }
      getCustomTemplates(query?: any) {
        return this.api.get(this.api.path + '/customTemplate/' , query)
      }
      getResellerCustomTemplates(id:any,query?: any) {
        return this.api.get(this.api.path + '/customTemplate/' + id , query)
      }
    
      getCustomTempalteTags() {
        return this.api.get(this.api.path + '/getTagName')
      }
     
    
      
      getEmails(query?: any) {
        return this.api.get(this.api.path + '/emailService', query)
      }
    
      deleteEmailsService(id: any) {
        return this.api.delete(this.api.path + '/emailService/' + id);
      }
    
      getEmailServices() {
        return this.api.post(this.api.path + '/emailService');
      }
      insertEmailServices(template: any) {
        return this.api.post(this.api.path + '/emailService', template);
      }
      updateEmailServices(id: any, data: any) {
        return this.api.post(this.api.path + '/emailService/' + id, data);
      }
    
      getCampaignEmailService() {
        return this.api.get(this.api.path + '/campaign/getEmailService')
      }
    
    
    
      insert(template: any) {
        return this.api.post(this.api.path + '/addCustomTemplate', template);
      }
      updateResellerTemplate(id: any, template: any) {
        return this.api.post(this.api.path + '/addCustomTemplate/' + id, template);
      }
      update(id: any, template: any) {
        return this.api.post(this.api.path + '/addCustomTemplate/' + id, template);
      }
      delete(id: number | any) {
        return this.api.delete(this.api.path + '/addCustomTemplate/' + id);
      }
    
      getAllUsersGroup(query?: any) {
        return this.api.get(this.api.path + '/membership/getAllUserDetails', query)
      }
      updateUserGroup(id: any, data: any) {
        return this.api.post(this.api.path + '/membership/' + id + '/updateContact', data);
      }
      getRecipientList(query?: any) {
        return this.api.get(this.api.path + '/recipientList', query);
      }
      getAllRecipientList() {
        return this.api.get(this.api.path + '/getAllRecipientList');
      }
      insertRecipientList(data: any) {
        return this.api.post(this.api.path + '/recipientList', data);
      }
      deleteRecepient(id: number | any) {
        return this.api.delete(this.api.path + '/recipientList/' + id);
      }
      updateRecipientList(id: any, data: any) {
        return this.api.post(this.api.path + '/recipientList/' + id, data);
      }
      getAllDomians() {
        return this.api.get(this.api.path + '/domain');
      }
      getRecipientCount() {
        return this.api.get(this.api.path + '/getRecipientCount');
      }
      getCampaigns(query?: any) {
        return this.api.get(this.api.path + '/campaign', query)
      }
      getResellerCampaigns(id: number, query?: any) {
        return this.api.get(this.api.path + '/campaign/' + id, query)
      }
      getResellerGateway(id: number) {
        return this.api.get(this.api.path + '/campaign/' + id + '/checkResellerGateway');
      }
    
      getScheduleCampaigns(query?: any) {
        return this.api.get(this.api.path + '/campaign/getScheduleCampaign', query)
      }
      
      rescheduleCampaign(id: any) {
        return this.api.post(this.api.path + '/campaign/' + id + '/rescheduleCampaign');
      }
      insertCampaign(data: any) {
        return this.api.post(this.api.path + '/campaign', data);
      }
    
      updateCampaign(id: any, data: any) {
        return this.api.post(this.api.path + '/campaign/' + id, data);
      }
      deleteCampaign(id: number | any) {
        return this.api.delete(this.api.path + '/campaign/' + id);
      }
      getAdminRecepientsDropdown() {
        return this.api.get(this.api.path + '/getAllRecipient');
      }
      getAllRecepientsDropdown(resId: number) {
        return this.api.get(this.api.path + "/" +  resId + '/getAllRecipient');
      }
      getAllTemplatesDropdown() {
        return this.api.get(this.api.path + '/getAllEmailTemplate');
      }
      getAllResellerTemplatesDropdown(id:any) {
        return this.api.get(this.api.path + "/" + id + '/getAllEmailTemplate');
      }
      getAllServicesDropdown() {
        return this.api.get(this.api.path + '/emailService/getAllServices');
      }
    
      getAllGateways(query?: any) {
        return this.api.get(this.api.path + '/emailSmsGatewaye', query);
      }
    
      insertGateway(data: any) {
        return this.api.post(this.api.path + '/emailSmsGatewaye', data);
      }
    
      updateGateway(id: any, data: any) {
        return this.api.post(this.api.path + '/emailSmsGatewaye/' + id, data);
      }
    
      deleteGateway(id: number | any) {
        return this.api.delete(this.api.path + '/emailSmsGatewaye/' + id);
      }
      deleteGatewayReseller(id: number | any) {
        return this.api.delete(this.api.path + '/emailSmsGatewaye/' + id);
      }
      deleteCampaignReport(id: number | any) {
        return this.api.delete(this.api.path + '/campaign/campaignReports/' + id);
      }
      deleteCampaignReportReseller(id: number | any) {
        return this.api.delete(this.api.path + '/campaign/campaignReports/' + id);
      }
    
      getAllCampaignsReport(query?: any) {
        return this.api.get(this.api.path + '/campaign/campaignReports', query);
      }
      getAllCampaignsReportReseller(id: number,query?: any) {
        return this.api.get(this.api.path + '/campaign/' + id + '/campaignReports', query);
      }
      getResellerEmails(resellerId: number) {
        return this.api.get(this.api.path + '/campaign/' + resellerId + '/getResellerEmailService');
      }
      getAllResellerUsersGroup(resId: number,query?: any,) {
        return this.api.get(this.api.path + "/recipient/" +  resId + '/recipientUser', query)
      }
      getReceiptDomain(resId: number) {
        return this.api.get(this.api.path + "/domains/" +  resId + '/getDomain');
      }
      getAllResellerRecipientList(resId: number) {
        return this.api.get(this.api.path + "/" +  resId + '/getAllRecipientList');
      }
      getReceiptList(resId: number,query?: any) {
        return this.api.get(this.api.path + '/recipientList/' + resId,query);
      }
      getReceiptCount(resId: number) {
          return this.api.get(this.api.path + "/" +  resId + '/getRecipientCount');
      }
      insertReceiptCount(resId: number,data:any) {
        return this.api.post(this.api.path + "/" +  resId + '/recipientList',data);
      }
      updateReceiptList(resId: number,data:any) {
        return this.api.post(this.api.path + '/recipientList/' + resId,data);
      }


}
