export const environment = {
    myOAccess: 'HkTA5LXCtffrUL244k',
    nodeId: '4',
    uofUrl: (betradarId: any, nodeId: any) => `https://https://uof.myodds.io/v1/pre/stateful_messages/events/sr%3Amatch:${betradarId}/initiate_request?node_id=${nodeId}`,
    production: false,
    test: true,
    _URL_: (() => {
        let i = 0, domain = document.domain, p = domain.split('.'), s = '_gd' + (new Date()).getTime();
        while (i < (p.length - 1) && document.cookie.indexOf(s + '=' + s) === -1) {
            domain = p.slice(-1 - (++i)).join('.');
            document.cookie = s + '=' + s + ';domain=' + domain + ';';
        }
        document.cookie = s + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + domain + ';';
        return window.location.protocol + '//api.' + domain + '/';
    })(),
};