import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/@services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from './@utils/services';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationStart, Router,
    RouterEvent
} from '@angular/router';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    constructor(private translate: TranslateService,
                private authService: AuthService,
                public loader: LoaderService,
                private router: Router) {
        this.authService.checkAuth();
        this.showLoaderOnRouteChange();
    }

    ngOnInit() {
        this.translate.setDefaultLang('tr');
        const browserLang: string = this.translate.getBrowserLang();
        let userLang              = localStorage.getItem('lang');
        if (!userLang) {
            userLang = (browserLang.match(/tr|en|de/)) ? browserLang : 'en';
        }
        this.translate.use(userLang);
        localStorage.setItem('lang', userLang);

    }

    private showLoaderOnRouteChange() {
        const routerEvents$ = this.router.events.subscribe((event: RouterEvent) => {
            if (event['id'] === 1 && event instanceof NavigationCancel) {
                // is cancelled
                setTimeout(() => {
                    this.loader.hide();
                }, 500);
                this.router.navigate(['/']);
            }
            if (event instanceof NavigationStart) {
                this.loader.show();
            }

            if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
                window.scrollTo(0, 0);
                setTimeout(() => {
                    this.loader.hide();
                }, 500);
            }
            // if (event instanceof ActivationStart) {
            //
            //     if (event.snapshot.data.hasOwnProperty('title')) {
            //         this.title.setTitle(event.snapshot.data.title);
            //     }
            //
            // }
        });
    }
}
