import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class EmployeeService {

    constructor(private api: ApiService) {
    }

    getMembers(resellerId: number, query?: any) {
        return this.api.get(this.api.path + '/employee', { ...query, ...{ resellerId: resellerId } });
    }

    getMember(id: number) {
        return this.api.get(this.api.path + '/employee/' + id);
    }

    getMemberOverview(id: number) {
        return this.api.get(this.api.path + '/employee/' + id + '/overview');
    }

    addMember(data: any) {
        return this.api.post(this.api.path + '/employee', data);
    }

    update(id: any, data: any) {
        return this.api.post(this.api.path + '/employee/' + id, data);
    }

    updateLimit(id: any, data: any) {
        return this.api.post(this.api.path + '/employee/' + id + '/limit', data);
    }

    getTransactions(id: number, query?: any) {
        return this.api.get(this.api.path + '/employee/transaction', { ...query, ...{ employeeId: id } });
    }

    getEmployee(id: number) {
        return this.api.get(this.api.path + '/employee/' + id);
    }

    removeEmployeeSession(id: any) {
        return this.api.delete(this.api.path + '/employee/' + id + '/online');
    }
}
