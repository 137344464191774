import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LayoutModule } from './@layout/layout.module';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './@core/core.module';
import { UtilsModule } from './@utils/utils.module';
import { AuthModule } from './auth/auth.module';
import './rxjs-operators';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppConfigService } from './app.config.service';
import { environment } from '../environments/environment';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, environment._URL_ + 'bet/config/translate/', '');
}

const appInitializerFn = (appConfig: AppConfigService) => {
    return () => {
        return Promise.all([appConfig.loadAppConfig(), appConfig.loadCountryConfig(), appConfig.loadSportConfig()]);
    };
};

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        LayoutModule.forRoot(),
        CoreModule.forRoot(),
        UtilsModule.forRoot(),
        AuthModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [AppConfigService]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
