import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

@Injectable()
export class EventService {

    constructor(private api: ApiService) {
    }

    getEvents(query?: any) {
        return this.api.get('admin/event', query);
    }

    getEvent(id: any) {
        return this.api.get('admin/event/' + id);
    }

    getSports(query?: any) {
        return this.api.get('admin/sport', query);
    }

    getAllSports() {
        return this.api.get('admin/sport/all');
    }

    getSport(id: any) {
        return this.api.get('admin/sport/' + id);
    }

    getCategories(query?: any) {
        return this.api.get('admin/category/', query);
    }

    getSportCategories(query?: any) {
        return this.api.get('admin/category/all', query);
    }

    getCategory(id: any) {
        return this.api.get('admin/category/' + id);
    }

    getTournaments(query?: any) {
        return this.api.get('admin/tournament/', query);
    }

    getCategoryTournaments(query?: any) {
        return this.api.get('admin/tournament/all', query);
    }

    getTournament(id: any) {
        return this.api.get('admin/tournament/' + id);
    }

    getEventMarkets(id: any) {
        return this.api.get('admin/event/' + id + '/market');
    }

    updateEvent(id: number, data: any) {
        return this.api.post('admin/event/' + id, data);
    }

    updateSport(id: any, data: any) {
        return this.api.post('admin/sport/' + id, data);
    }

    updateCategory(id: any, data: any) {
        return this.api.post('admin/category/' + id, data);
    }

    updateTournament(id: any, data: any) {
        return this.api.post('admin/tournament/' + id, data);
    }

    changeMarketStatus(id: any, data: any) {
        return this.api.post('admin/event/' + id + '/changeMarketStatus', data);
    }

    revokeMarketResult(id: any, data: any) {
        return this.api.post('admin/event/' + id + '/revokeMarketResult', data);
    }

    completeMarketResult(id: any, data: any) {
        return this.api.post('admin/event/' + id + '/completeMarketResult', data);
    }

    cancelMarketResult(id: any, data: any) {
        return this.api.post('admin/event/' + id + '/cancelMarketResult', data);
    }

    getLiveEvents(query?: any) {
        return this.api.get('admin/liveEvent', query);
    }
}
