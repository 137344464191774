import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class SessionService {

    constructor(private apiService: ApiService) {
    }

    login(credentials, type) {
        if (type === 1) {
            return this.apiService.post('admin/session/authenticate', credentials);
        }
        else if (type === -1) {
            return this.apiService.post('employee/session/authenticate', credentials);
        }
        return this.apiService.post('reseller/session/authenticate', credentials);
    }

    logout(type) {
        if (type === 1) {
            return this.apiService.get('admin/session/logout');
        }
        else if (type === -1) {
            return this.apiService.get('employee/session/logout');
        }
        return this.apiService.get('reseller/session/logout');
    }

    refresh(type) {
        if (type === 1) {
            return this.apiService.get('admin/session/me');
        }
        else if (type === -1) {
            return this.apiService.get('employee/session/me');
        }
        return this.apiService.get('reseller/session/me');
    }

    setPath(type: number) {
        if (type === 1) {
            this.apiService.path = 'admin';
        }
        else if (type === -1) {
            this.apiService.path = 'employee';
        }
        else {
            this.apiService.path = 'reseller';
        }
    }
}
