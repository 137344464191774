import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

@Injectable()
export class MembershipService {

    constructor(private api: ApiService) {
    }

    getUserDocuments(id: any , query?: any) {
        return this.api.get(this.api.path + '/documents/', {...query, ...{userId: id}});
    }
    getUserImages(id:any) {
        return this.api.get(this.api.path + '/single-user-images/' + id);
    }
    // getAllUserImages() {
    //     return this.api.get(this.api.path + '/all-user-images/');
    // }
    documentReject(id: number, query: any){
        return this.api.post(this.api.path + '/documentReject/' + id, query);
    }
    documentAccept(id: number, query: any){
        // console.log(id, query); 
        return this.api.post(this.api.path + '/documentAccept/' + id, query);
    }
    // docAccpet
    getMembers(query?: any) {
        return this.api.get(this.api.path + '/membership', query);
    }

    insert(data: any) {
        return this.api.post(this.api.path + '/membership', data);
    }

    update(id: any, data: any) {
        return this.api.post(this.api.path + '/membership/' + id, data);
    }

    delete(id: any) {
        return this.api.delete(this.api.path + '/membership/' + id);
    }

    getMemberOverview(id: number) {
        return this.api.get(this.api.path + '/membership/' + id + '/overview');
    }

    getMemberWallet(id: number) {
        return this.api.get(this.api.path + '/membership/' + id + '/wallet');
    }

    getMember(id: number) {
        return this.api.get(this.api.path + '/membership/' + id);
    }

    forgetPassword(id: number) {
        return this.api.post(this.api.path + '/membership/' + id + '/forget');
    }

    resetGoogleAuth(id: number) {
        return this.api.post(this.api.path + '/membership/' + id + '/reset');
    }

    getWithdrawalAmount(id: number) {
        return this.api.get(this.api.path + '/membership/' + id + '/isWithdrawal');
    }

    blockWallet(id: number, data: any) {
        return this.api.post(this.api.path + '/membership/' + id + '/walletBlocked', data);
    }

    updateWallet(id: any, data: any) {
        return this.api.post(this.api.path + '/membership/' + id + '/wallet', data);
    }

    getMemberTransactions(id: number, query?: any) {
        return this.api.get(this.api.path + '/transaction/', {...query, ...{userId: id}});
    }

    getMemberCoupons(id: number, query?: any) {
        return this.api.get(this.api.path + '/coupon/', {...query, ...{userId: id}});
    }

    getMemberBonusRules(id: number, query?: any) {
        return this.api.get(this.api.path + '/membership/' + id + '/bonus', query);
    }

    addBonus(id: number, data: any) {
        return this.api.post(this.api.path + '/membership/' + id + '/bonus', data);
    }

    getMemberNotes(id: number, query?: any) {
        return this.api.get(this.api.path + '/membership/' + id + '/note', query);
    }

    insertMemberNote(id: number, data: any) {
        return this.api.post(this.api.path + '/membership/' + id + '/note', data);
    }

    getMemberLimits(id: number, query?: any) {
        return this.api.get(this.api.path + '/membership/' + id + '/limit', query);
    }

    getMemberAccessLogs(id: number, query?: any) {
        return this.api.get(this.api.path + '/membership/' + id + '/access', query);
    }

    removeMemberSession(id: any) {
        return this.api.delete(this.api.path + '/membership/' + id + '/online');
    }

    addMember(data: any) {
        return this.api.post(this.api.path + '/membership', data);
    }

    getUserOptions(query?: any) {
        return this.api.get(this.api.path + '/membership/option', query);
    }

    updateUserOption(id: any, userId: any, formData: any) {
        return this.api.post(this.api.path + '/membership/' + userId + '/option/' + id, formData);
    }
    getAllMembers(query?: any) {
        return this.api.get(this.api.path + '/membership/all');
    }

    /* reseller overview user data */
    getAllResellerUsers(query?: any) {
        return this.api.get(this.api.path + '/membership/resellerUsers');
    }
    /*  */

}
