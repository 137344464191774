import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class PaymentService {

    constructor(private api: ApiService) {
    }

    getWithdraws(query?: any) {
        return this.api.get(this.api.path + '/withdraw', query);
    }
    getResellerWithdraws(id: any, query?: any) {
        return this.api.get(this.api.path + '/withdraw/' + id, query);
    }

    approveWithdraw(query?: any) {
        return this.api.post(this.api.path + '/withdraw', query);
    }

    cancelWithdraw(data: any) {
        return this.api.post(this.api.path + '/withdraw', data);
    }

    updateWithdraw(id: number, query?: any) {
        return this.api.post(this.api.path + '/withdraw/' + id, query);
    }
    getDeposits(query?: any) {
        return this.api.get(this.api.path + '/deposit', query);
    }
    getDepositsReseller(id: any, query?: any,) {
        return this.api.get(this.api.path + '/deposit/' + id, query);
    }
    updateDeposit(id: number, query?: any) {
        return this.api.post(this.api.path + '/deposit/' + id, query);
    }
    depositDeleteRecords(query?: any) {
        return this.api.get(this.api.path + '/depositDeleteRecords/', query);
    }
    
    approveDeposit(query?: any) {
        return this.api.post(this.api.path + '/deposit', query);
    }

    cancelDeposit(query?: any) {
        return this.api.post(this.api.path + '/deposit', query);
    }

    getPaymentMethods(query?: any) {
        return this.api.get(this.api.path + '/paymentMethod', query);
    }

    getAllPaymentMethods() {
        return this.api.get(this.api.path + '/paymentMethod/all');
    }

    getPaymentMethodAccounts(query?: any) {
        return this.api.get(this.api.path + '/paymentMethod/account', query);
    }

    getAllPaymentMethodAccounts() {
        return this.api.get(this.api.path + '/paymentMethod/account/all');
    }

    getBanks(query?: any) {
        return this.api.get(this.api.path + '/paymentMethod/bank', query);
    }

    getAllBanks(query?: any) {
        return this.api.get(this.api.path + '/paymentMethod/bank/all', query);
    }

    updatePaymentMethodAccount(id: any, data: any) {
        return this.api.post(this.api.path + '/paymentMethod/account/' + id, data);
    }

    insertPaymentMethodAccount(data: any) {
        return this.api.post(this.api.path + '/paymentMethod/account/', data);
    }
    insertPaymentMethod(data: any) {
        return this.api.post(this.api.path + '/paymentMethod', data);
    }

    updatePaymentMethod(id: any, data: any) {
        return this.api.post(this.api.path + '/paymentMethod/' + id, data);
    }


    insertBank(data: any) {
        return this.api.post(this.api.path + '/paymentMethod/bank/', data);
    }

    updateBank(id: any, data: any) {
        return this.api.post(this.api.path + '/paymentMethod/bank/' + id, data);
    }

    saveDeposit(data: any) {
        return this.api.post(this.api.path + '/deposit', data);
    }

    saveWithdraw(data: any) {
        return this.api.post(this.api.path + '/withdraw', data);
    }

    getAllResellers(id: any) {
        return this.api.get(this.api.path + '/resellerMember/all/' + id);

    }
    getResllerPaymentMethods() {
        return this.api.get(this.api.path + '/paymentMethod/all');
    }

    getPaymentTypes(query?: any) {
        return this.api.get(this.api.path + '/paymentMethod/all');
    }
    getPaymentMethod() {
        return this.api.get(this.api.path + '/withdraw/getAllPaymentMethod');
    }

    addToProcess(amount: any, currencyFrom: any, currencyTo: any, address: any, data: any) {
        return this.api.post(`${this.api.path}/coinspaid/withdraw/${amount}/${currencyFrom}/${currencyTo}/${address}`, data);
    }
    getPaymentGateways(query?: any) {
        return this.api.get(this.api.path + '/paymentMethod/paymentGateway', query);
    }

}
