import {Injectable} from '@angular/core';
import { Reseller } from '../modals';
import {ApiService} from './api.service';

@Injectable()
export class OptionService {

    constructor(private api: ApiService) {
    }

    getResellerOptionKeys(query?: any) {
        return this.api.get(this.api.path + '/option/reseller', query);
    }

    getAllResellerOptionKeys(resellerId?: any) {
        return this.api.get(this.api.path + '/option/reseller/all', {resellerId});
    }

    getAllUserOptionKeys() {
        return this.api.get(this.api.path + '/option/user/all');
    }

    updateResellerOptionKey(id: any, data: any) {
        return this.api.post(this.api.path + '/option/reseller/' + id, data);
    }

    insertResellerOptionKey(data: any) {
        return this.api.post(this.api.path + '/option/reseller', data);
    }

    getResellerLanguages(resellerId,data: any) {
        return this.api.get(this.api.path + '/reseller/' + resellerId + '/language',data);

    }
    addResellerLanguages(resellerId: any, data: any) {
        return this.api.post(this.api.path + '/reseller/' + resellerId + '/language', data);
      }
      
      updateResellerLanguages(resellerId: any, data: any,id:any) {
        return this.api.post(this.api.path + '/reseller/' + resellerId + '/language/' + id, data);
      }
      deleteResellerLanguages(resellerId: any,id:any) {
        return this.api.delete(this.api.path + '/reseller/' + resellerId + '/language/' + id);
      }
}
