import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../@utils/services';
import { AuthService } from '../auth/@services/auth.service';
import { UserInterface } from '../@core/modals';
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { ResellerService } from "../@core/services/reseller.service";
import { CouponService, OperatorService, MembershipService, SupportMessageService, PermissionService } from "src/app/@core/services";
import {  HostListener, Input, ViewChild, } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Table } from "primeng/table";
import { CountUp } from 'countup.js';
import { MenuItem } from 'primeng/api';
import { TranslateService } from "@ngx-translate/core";
import { AppConfigService } from "src/app/app.config.service";
import { trigger, state, style, animate, transition } from '@angular/animations';
import { UserType } from '../@core/modals/user-type';

@Component({
    selector   : 'app-pages',
    templateUrl: 'pages.component.html',
    styleUrls: ['pages.component.css']
    })

export class PagesComponent implements OnInit {
    isMenuExpanded = true;
    private _menuMode  = 'horizontal';
    menuClick: boolean = false;
    sidebarExpanded = true;
    sidebarVisible = true;
    sidebarActive = false;
    model: MenuItem[] = []; // Initialize an empty array for menu items
    sidebarActivee = false;
    get menuMode(): string {
        return this._menuMode;
    }

    set menuMode(value: string) {
        this._menuMode = value;
        this.localStorage.set('menuMode', value);
    }

    public menuActive = false;
    public user: UserInterface;

    constructor(private localStorage: LocalStorageService, private authService: AuthService, private router: Router,
        public http: HttpClient,
        private route: ActivatedRoute,
        private operatorService: OperatorService,
        private memberService: MembershipService,
        private resellerServrice: ResellerService,
        private couponService: CouponService,
        private supportMessageService: SupportMessageService,
        private translate: TranslateService,
        private configService: AppConfigService,
        private permissionService: PermissionService,
        ) {
    }

    ngOnInit() {
        this.loadMenuMode();
        this.getUser();
        // this.layoutService.isNavActive$.subscribe((active) => {
        //     this.isNavActive = active;
        //   });\

    }
    // @HostListener('window:resize', ['$event'])
    // onResize(event) {
    //   if (window.innerWidth < 768) {
    //     this.onHideSidebar();
    //   }
    // }
    // onHideSidebar() {
    //     this.sidebarActive = false;
    //   }
    toggleSidebar() {
        this.sidebarActive = !this.sidebarActive;
      }

      updateSidebarActive(value: boolean) {
        this.sidebarActive = value;
      }

    // onSidebarToggled(expanded: boolean): void {
    //     this.sidebarExpanded = expanded;
    //  }
    private getUser() {
        this.authService.user.subscribe((user: UserInterface) => {
            this.user = user;

            if(this.user.userType === UserType.EMPLOYEE) {
                this.router.navigate(['/pages/terminal-actions']);
            }
        });
    }

    logout() {
        this.authService.logout(this.user.userType).subscribe(() => {
            this.router.navigate(['/auth']);

        });
    }

    private loadMenuMode() {
        const menuMode = this.localStorage.get('menuMode');        
        if (menuMode) {
            this._menuMode = menuMode;
        }
    }
    onMenuClick($event) {
        this.menuClick = true;

    }
}
