import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class FooterService {

  constructor(private api: ApiService) { }

  insertFooter(data) {
    return this.api.post(this.api.path + '/footer', data);
  }

  insertFooterReseller(data) {
    return this.api.post(this.api.path + '/footerReseller', data);
  }

  updateFooter(id: any, data) {
    return this.api.post(this.api.path + '/footer/' + id, data);
  }

  getFooter(query?: any) {
    return this.api.get(this.api.path + '/footer', query);
  }

  updateFooterReseller(id: any, data) {
    return this.api.post(this.api.path + '/footerReseller/' + id, data);
  }


  getFooterReseller(id: any, query?: any) {
    return this.api.get(this.api.path + '/footerReseller/' + id, query);
  }
  deleteFooter(id: number) {
    return this.api.delete(this.api.path + '/footer/' + id);
  }

  deleteFooterReseller(id: number) {
    return this.api.delete(this.api.path + '/footerReseller/' + id);
  }

  getFooterList() {
    return this.api.get(this.api.path + '/getFooterList');
  }
  getFootersLits(id) {
    return this.api.get(this.api.path + '/getFooterList/' + id);
  }

  footerList() {
    return this.api.get(this.api.path + '/footerList');
  }

  insertFooterCategory(data) {
    return this.api.post(this.api.path + '/footerCategory', data);
  }

  updateFooterCategory(id: number, data) {
    return this.api.post(this.api.path + '/footerCategory/' + id, data);
  }
  getFooterCategory(query?: any) {
    return this.api.get(this.api.path + '/footerCategory', query);
  }

  getFooterCategoryList() {
    return this.api.get(this.api.path + '/getFooterCategoryList');
  }

  deleteCategory(id: number) {
    return this.api.delete(this.api.path + '/footerCategory/' + id);
  }


  insertFooterItem(data) {
    return this.api.post(this.api.path + '/footerItem', data);
  }

  updateFooterItem(id: number, data) {
    return this.api.post(this.api.path + '/footerItem/' + id, data);
  }
  getFooterItems(query?: any) {
    return this.api.get(this.api.path + '/footerItem', query);
  }

  deleteItem(id: number) {
    return this.api.delete(this.api.path + '/footerItem/' + id);
  }

  insertFooterCategoryReseller(data) {
    return this.api.post(this.api.path + '/ResellerfooterCategory', data);
  }

  updateFooterCategoryReseller(id: number, data) {
    return this.api.post(this.api.path + '/ResellerfooterCategory/' + id, data);
  }

  getFooterCategoryReseller(id: any, query?: any) {
    return this.api.get(this.api.path + '/ResellerfooterCategory/' + id + '/', query);
  }
  deleteCategoryReseller(id: number) {
    return this.api.delete(this.api.path + '/ResellerfooterCategory/' + id);
  }

  updateFooterItemReseller(id: number, data) {
    return this.api.post(this.api.path + '/ResellerFooterItem/' + id, data);
  }
  getFooterItemsReseller(id: any, query?: any) {
    return this.api.get(this.api.path + '/ResellerFooterItem/' + id + '/', query);
  }
  deleteItemReseller(id: number) {
    return this.api.delete(this.api.path + '/ResellerFooterItem/' + id);
  }
  getFooterCategoryListReseller(id: any) {
    return this.api.get(this.api.path + '/footerCategory/' + id);
  }
  insertFooterItemReseller(data) {
    return this.api.post(this.api.path + '/ResellerFooterItem', data);
  }

}
