export class Odds {
    odd: any;
    outCome: any;
    marketId: any;
    sportId: any;
    isLive: any;
    specialBetValue: any;
    teamName: any;
    outright: any;
    status: any;
    banker: any;
    eventId: any;
    name: any;
    startDate: any;
    score: any;
    eventResult: any;
    matchTime: any;

    constructor(data?: any) {
        Object.assign(this, data);
    }
}
