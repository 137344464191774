import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class BonusService {

    constructor(private api: ApiService) {
    }

    getBonuses(query?: any) {
        return this.api.get(this.api.path + '/bonus', query);
    }

    insert(data: any) {
        return this.api.post(this.api.path + '/bonus', data);
    }

    update(id: any, data: any) {
        return this.api.post(this.api.path + '/bonus/' + id, data);
    }

    delete(id: number | any) {
        return this.api.delete(this.api.path + '/bonus/' + id);
    }

    getAllActiveBonuses() {
        return this.api.get(this.api.path + '/bonus/all');
    }
}
