import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../environments/environment";

@Injectable()
export class AppConfigService {
    couponConfig;
    liveConfigs;
    preMatchConfigs;
    constants: any = [];
    countries: any;
    currencies: any;
    dashboard: any;
    site: any = {};
    filters: any = {};
    isWeb = 0;
    channel: string;
    outputHash = "{{OUTPUT_HASH_HERE}}";
    markets: any;
    sportMarkets: any;
    marketGroups: any;
    sportMarketGroups: any;
    usernameCache: any;
    passwordCache: any;

    constructor(private http: HttpClient) {}

    loadAppConfig() {
        return this.http
            .get(environment._URL_ + "bet/config/admin-config")
            .toPromise()
            .then((res: any) => {
                const configs = res.data;
                this.dashboard = configs.dashboard;
                this.site = configs.site || {};
                this.isWeb = configs.isWeb;
                this.channel = configs.isWeb
                    ? "liveFeedChannel_web"
                    : "liveFeedChannel";
                this.constants = configs.constant;
                this.setFilters(configs.constant);
            });
    }

    loadSportConfig() {
        return this.http
            .get(environment._URL_ + "bet/config/sport")
            .toPromise()
            .then((res: any) => {
                this.setSportConfigs(res.data);
            });
    }

    loadCountryConfig() {
        return this.http
            .get(environment._URL_ + "bet/config/country")
            .toPromise()
            .then((res: any) => {
                const configs = res.data;
                this.currencies = configs.currency || [];
                this.countries = configs.country || [];
            });
    }
    countryConfig(query?: any) {
        return this.http.get(environment._URL_ + "bet/config/country", query);
    }
    getCountries() {
        return this.countries;
    }

    getCurrencies() {
        return this.currencies;
    }

    isPrivate() {
        return this.site["private"] || 0;
    }

    getLogo() {
        return this.site["logo"] || null;
    }

    getLoadingLogo() {
        return this.site["loadingLogo"] || null;
    }

    getChatKey() {
        return this.site["chatKey"] || null;
    }

    getMarketName(sportId, marketId, lang, sbv?, category?) {
        const market = this.markets[marketId];
        switch (market["sbv"]) {
            case "1":
                if (sbv && !category) {
                    return market.name[lang] + " (" + sbv + ")";
                }
                return market.name[lang];
            case "2":
                return this.parseLiveMarketName(market.name[lang], sbv);
            case "3":
                return market.name[lang].replace("X", sbv);
            default:
                return market.name[lang];
        }
    }

    getOutcome(marketId, outCome, lang, outright?) {
        if (!outCome) {
            return outCome;
        }
        const outcomes = this.markets[marketId]["outcomes"] || null;
        if (outcomes) {
            return outcomes.hasOwnProperty(outCome)
                ? outcomes[outCome][lang]
                : outCome;
        }
        return outCome;
    }

    getSportDefaultMarkets(sportId, isLive?, all?) {
        const matchType = isLive ? "live" : "prematch";
        let defaultMarkets = this.sportMarkets[matchType].hasOwnProperty(
            sportId
        )
            ? this.sportMarkets[matchType][sportId]
            : [];
        if (!all) {
            defaultMarkets = Object.values(defaultMarkets).slice(0, 4);
        }

        return defaultMarkets;
    }

    getSportMarketGroups(sportId) {
        return this.sportMarketGroups[sportId];
    }

    getMarketGroupName(key, lang) {
        return this.marketGroups.hasOwnProperty(key)
            ? this.marketGroups[key][lang]
            : "others";
    }

    private getPreMatchMarketGroupName(key, lang) {
        return this.preMatchConfigs["marketGroups"].hasOwnProperty(key)
            ? this.preMatchConfigs["marketGroups"][key][lang]
            : "others";
    }

    private getLiveMarketGroupName(key, lang) {
        if (!this.liveConfigs) {
            return "";
        }
        return this.liveConfigs["marketGroups"].hasOwnProperty(key)
            ? this.liveConfigs["marketGroups"][key][lang]
            : "others";
    }

    private parseLiveMarketName(marketName, sOval) {
        /**
         * Main regex for splitting sOval
         * @type {RegExp}
         */
        let regex = /\-|\||and|\//;
        /**
         * Temp Variable for saving splitted sOval
         * @type {Array}
         */
        let sOvalArr = [];

        /**
         * Market name
         * @type {String}
         *
         /**
         * Regex used to check if market name contains any potential sOval match
         * @type {Array}
         */
        const matches = marketName.match(/\[(\w+!?)\]|xth/gi);

        /**
         * if the value of sOval starts with '-' change the main regex for splitting sOval by ignoring '-'
         */
        if (/^\-/.test(sOval) || /-\w\.\w/.test(sOval)) {
            regex = /\||and|\//;
            sOvalArr = sOval ? sOval.toString().split(regex) : "";
        } else {
            sOvalArr = sOval ? sOval.toString().split(regex) : "";
        }

        if (matches !== null && matches.length > 0) {
            let valN;
            let num;
            for (const i in matches) {
                if (matches.hasOwnProperty(i)) {
                    valN = matches[i].replace(/[\]\[]/g, "");
                    num = valN.substring(valN.length - 1, valN.length);
                    marketName = marketName.replace(
                        matches[i],
                        sOvalArr[num - 1] || "^"
                    );
                }
            }
        }
        return marketName;
    }

    getLiveSportMarketConfigs(sportId) {
        if (!this.liveConfigs) {
            return [];
        }

        return this.liveConfigs["markets"][sportId] || [];
    }

    getFilters(key) {
        if (!this.filters) {
            return [];
        }
        return this.filters.hasOwnProperty(key) ? this.filters[key] : [];
    }

    getConstant(key) {
        if (!this.constants) {
            return [];
        }
        return this.constants.hasOwnProperty(key) ? this.constants[key] : [];
    }

    private setFilters(constants: any) {
        for (const key in constants) {
            if (constants.hasOwnProperty(key)) {
                this.filters[key] = this.filters[key] || [];
                for (const value in constants[key]) {
                    if (constants[key].hasOwnProperty(value)) {
                        let label = constants[key][value];
                        if (key === "walletTypes") {
                            label = constants[key][value]["name"];
                        }
                        this.filters[key].push({ label, value });
                    }
                }
            }
        }
    }

    private setSportConfigs(config: any) {
        this.markets = config["ALL_MARKETS"];
        this.sportMarkets = config["SPORT_MARKETS"];
        this.marketGroups = config["MARKET_GROUPS"];
        this.sportMarketGroups = config["SPORT_GROUP_MARKETS"];
        this.couponConfig = config["COUPON"];
    }

    getLanguages() {
        return this.dashboard["SITE"]["CONFIG"]["LANGUAGES"];
    }
    getDomains() {
        return this.site.domain;
    }
    getSportMarkets(sportId?, type?) {
        let markets = this.getSportDefaultMarkets(sportId, type, true);
        markets = Object.values(markets);
        markets = markets.map((market) => {
            return this.markets[market.mId];
        });
        return markets;
    }

    getTagRequirdStatus(){
        return this.dashboard["SITE"]["CONFIG"]["REQUIRED_STATUS"];
    }

    getTagType(){
        return this.dashboard["SITE"]["CONFIG"]["TAG_TYPE"];
    }
}
