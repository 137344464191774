import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { isPrimitive } from 'util';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiService {

    private httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
    };
    path: string;

    constructor(public http: HttpClient) {
    }

    public post(path: string, body: object = {}): Observable<any> {
        return this.http.post(
            `${environment._URL_}${path}`,
            this._preparePostString(body),
            this.httpOptions
        );
    }

    public get(path: string, body: object = {}): Observable<any> {

        return this.http.get(
            `${environment._URL_}${path}`,
            {params: this.objectToParams(body)}
        );
    }


    _preparePostString(args: object): string {
        return $.param(args);
    }

    objectToParams(body: object) {
        let params = new HttpParams();

        Object.keys(body).filter((value) => {
            const objectValue = isPrimitive(body[value]) ? body[value] : JSON.stringify(body[value]);
            return (objectValue == null || objectValue === undefined || objectValue === 'Invalid' +
                ' date' || objectValue === '') ? false : true;
        }).map((value) => {
            const objectValue = isPrimitive(body[value]) ? body[value] : JSON.stringify(body[value]);
            params = params.append(value, objectValue);
            return `${value}=${objectValue}`;
        });
        /*.join('&');*/
        return params;
    }

    delete(path: string) {
        return this.http.delete(`${environment._URL_}${path}`, this.httpOptions);
    }

    public postFile(path: string, body): Observable<any> {
        return this.http.post(
            `${environment._URL_}${path}`,
            body
        );
    }
}
