import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class MarketService {

    constructor(private api: ApiService) {
    }

    getMarkets(query?: any) {
        return this.api.get('admin/market', query);
    }

    updateMarket(id: any, data: any) {
        return this.api.post('admin/market/' + id, data);
    }

    getOutcomes(query?: any) {
        return this.api.get('admin/market/outcome', query);
    }

    updateOutcome(id: any, data: any) {
        return this.api.post('admin/market/outcome/' + id, data);
    }

    getSportMarketConfigs(query?: any) {
        return this.api.get('admin/market/sportMarketConfig', query);
    }

    getGroups(query?: any) {
        return this.api.get('admin/market/group', query);
    }

    insertGroup(id: any, data: any) {
        return this.api.post('admin/market/group/', data);
    }

    updateGroup(id: any, data: any) {
        return this.api.post('admin/market/group/' + id, data);
    }

    getAllMarkets() {
        return this.api.get('admin/market/all');
    }

    updateSportMarket(id: any, data: any) {
        return this.api.post('admin/market/sportMarketConfig/' + id, data);
    }

    deleteGroup(id: any) {
        return this.api.delete('admin/market/group/' + id);
    }

    getGroupMarkets(query?: any) {
        return this.api.get('admin/market/groupMarket', query);
    }

    getGroupSportMarkets(groupId: any, sportId: any) {
        return this.api.get('admin/market/groupMarket/' + groupId + '/' + sportId);
    }

    saveGroupSportMarket(data: any) {
        return this.api.post('admin/market/groupMarket/', data);
    }

    updateMarketMargin(id: any, data: any) {
        return this.api.post('admin/market/margin/' + id, data);
    }
}
