import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NoAuthGuard } from './auth/@services/no-auth-guard.service';
import { AuthGuard } from './auth/@services/auth-guard.service';

export const routes: Routes = [
    {path: '', redirectTo: 'auth', pathMatch: 'full'},
    {path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), canLoad: [NoAuthGuard]},
    {path: 'pages', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canLoad: [AuthGuard]},
    {path: '**', redirectTo: 'pages'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: false, preloadingStrategy: PreloadAllModules})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
