import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

@Injectable()
export class FilialeService {

    constructor(private api: ApiService) {
    }

    getMembers(query?: any) {
        return this.api.get(this.api.path + '/filiale', query);
    }

    insert(data: any) {
        return this.api.post(this.api.path + '/filiale', data);
    }

    update(id: any, data: any) {
        return this.api.post(this.api.path + '/filiale/' + id, data);
    }

    delete(id: any) {
        return this.api.delete(this.api.path + '/filiale/' + id);
    }

    getMemberOverview(id: number) {
        return this.api.get(this.api.path + '/filiale/' + id + '/overview');
    }

    getMemberWallet(id: number) {
        return this.api.get(this.api.path + '/filiale/' + id + '/wallet');
    }

    getMember(id: number) {
        return this.api.get(this.api.path + '/filiale/' + id);
    }

    forgetPassword(id: number) {
        return this.api.post(this.api.path + '/filiale/' + id + '/forget');
    }

    resetGoogleAuth(id: number) {
        return this.api.post(this.api.path + '/filiale/' + id + '/reset');
    }

    getWithdrawalAmount(id: number) {
        return this.api.get(this.api.path + '/filiale/' + id + '/isWithdrawal');
    }

    blockWallet(id: number, data: any) {
        return this.api.post(this.api.path + '/filiale/' + id + '/walletBlocked', data);
    }

    updateWallet(id: any, data: any) {
        return this.api.post(this.api.path + '/filiale/' + id + '/wallet', data);
    }

    getMemberTransactions(id: number, query?: any) {
        return this.api.get(this.api.path + '/transaction/', {...query, ...{userId: id}});
    }

    getMemberCoupons(id: number, query?: any) {
        return this.api.get(this.api.path + '/coupon/', {...query, ...{userId: id}});
    }

    getMemberBonusRules(id: number, query?: any) {
        return this.api.get(this.api.path + '/filiale/' + id + '/bonus', query);
    }

    addBonus(id: number, data: any) {
        return this.api.post(this.api.path + '/filiale/' + id + '/bonus', data);
    }

    getMemberNotes(id: number, query?: any) {
        return this.api.get(this.api.path + '/filiale/' + id + '/note', query);
    }

    insertMemberNote(id: number, data: any) {
        return this.api.post(this.api.path + '/filiale/' + id + '/note', data);
    }

    getMemberLimits(id: number, query?: any) {
        return this.api.get(this.api.path + '/filiale/' + id + '/limit', query);
    }

    getMemberAccessLogs(id: number, query?: any) {
        return this.api.get(this.api.path + '/filiale/' + id + '/access', query);
    }

    removeMemberSession(id: any) {
        return this.api.delete(this.api.path + '/filiale/' + id + '/online');
    }

    addMember(data: any) {
        return this.api.post(this.api.path + '/filiale', data);
    }

    getUserOptions(query?: any) {
        return this.api.get(this.api.path + '/filiale/option', query);
    }

    updateUserOption(id: any, userId: any, formData: any) {
        return this.api.post(this.api.path + '/filiale/' + userId + '/option/' + id, formData);
    }

    updateFilialeConfig(id: any, data: any) {
        return this.api.post(this.api.path + '/filiale/' + id + '/config', data);
    }

    reinstallFiliale(id: any, data: any) {
        return this.api.post(this.api.path + '/filiale/' + id + '/reinstall', data);
    }
}
