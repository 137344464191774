import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class GameService {

    constructor(private api: ApiService) {
    }

    getAllDomians() {
        return this.api.get(this.api.path + '/domain');
    }
    getAllProviders() {
        return this.api.get(this.api.path + '/game/provider/all');
    }

    getProviders(query?: any) {
        return this.api.get(this.api.path + '/game/provider', query);
    }

    updateProvider(id: any, data: any) {
        return this.api.post(this.api.path + '/game/provider/' + id, data);
    }

    getAllGames() {
        return this.api.get(this.api.path + '/game/all');
    }

    getAllClasses() {
        return this.api.get(this.api.path + '/game/classes');
    }

    getGames(query?: any) {
        return this.api.get(this.api.path + '/game/', query);
    }

    updateGame(id: any, data: any) {
        return this.api.post(this.api.path + '/game/' + id, data);
    }

    getCategories(query?: any) {
        return this.api.get(this.api.path + '/game/category', query);
    }

    insertCategory(data: any) {
        return this.api.post(this.api.path + '/game/category/', data);
    }

    updateCategory(id: any, data: any) {
        return this.api.post(this.api.path + '/game/category/' + id, data);
    }

    getGameCategories(categoryId) {
        return this.api.get(this.api.path + '/game/category/' + categoryId + '/providerGames');
    }

    saveGameCategories(id: any, data: any) {
        return this.api.post(this.api.path + '/game/category/' + id + '/providerGame/', data);
    }

    getGameBets(query?: any) {
        return this.api.get(this.api.path + '/gameBet', query);
    }
    getDomainPromotion() {
        return this.api.post(this.api.path + '/promotions-domain');
        // return this.api.get();
    }

    duplicateCategories(id: number) {
        return this.api.post(this.api.path + '/game/category/' + id + '/duplicate');
    }

    deleteGameCategory(categoryId) {
        return this.api.delete(this.api.path + '/game/category/' + categoryId + '/delete');
    }

    getCustomProviders(query?: any) {
        return this.api.get(this.api.path + '/game/providerCategory', query);
    }

    insertCustomCategory(data: any) {
        return this.api.post(this.api.path + '/game/providerCategory', data);
    }

    updateCustomCategory(id: any, data: any) {
        return this.api.post(this.api.path + '/game/providerCategory/' + id, data);
    }

    deleteCustomCategory(categoryId) {
        return this.api.delete(this.api.path + '/game/providerCategory/' + categoryId + '/delete');
    }
}
