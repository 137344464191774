import { Injectable } from '@angular/core';
import { LocalStorageService } from './localStorage.service';

@Injectable()
export class JwtService {

    constructor(private localStorage: LocalStorageService) {
        this.checkToken();
    }

    public getToken(): string {
        return this.localStorage.get('token');
    }

    public saveToken(token: string) {
        this.localStorage.setWithTtl('token', token);
    }

    public destroyToken() {
        this.localStorage.remove('token');
    }

    public checkToken() {
        const token = this.getToken();
        if (!token) {
            this.destroyToken();
        }

    }
}