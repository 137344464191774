import { ModuleWithProviders, NgModule } from '@angular/core';
import {
    DateTimeService,
    LocalStorageService,
    JwtService, LoaderService
} from './services';
import { FieldMessageComponent, FormMessageComponent, LoaderComponent, PDialogCustomComponent, TableFilterComponent } from './components';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutModule } from '../@layout/layout.module';
import { CustomMinDirective } from './directives/min.directive';
import { CustomMaxDirective } from './directives/max.directive';

const COMPONENTS = [
    FieldMessageComponent,
    FormMessageComponent,
    TableFilterComponent,
    CustomMinDirective,
    CustomMaxDirective,
    PDialogCustomComponent, LoaderComponent
];

@NgModule({
    imports     : [LayoutModule, TranslateModule],
    exports     : [...COMPONENTS],
    declarations: [...COMPONENTS],
    providers   : []
})
export class UtilsModule {
    static forRoot(): ModuleWithProviders<UtilsModule> {
        return {
            ngModule : UtilsModule,
            providers: [DateTimeService, LocalStorageService, JwtService, LoaderService]
        };
    }
}
