import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

    private TTL = 60;

    constructor() {
    }

    get(key) {
        let data = localStorage.getItem(key);
        try {
            data = JSON.parse(data);
        } catch (e) {
            console.error('LocalStorage.get(' + key + ') =>', e);
        }
        const now = Math.round(new Date().getTime() / 1000);
        if (data == null || !data.hasOwnProperty('data')) {
            return null;
        }
        // if (!data.hasOwnProperty('expire') || data['expire'] < now) {
        //     return null;
        // }
        return data['data'];
    }

    set(key, value) {
        this.setWithTtl(key, value, 6000);
    }

    setWithTtl(key, value, ttl = this.TTL) {
        const expTime = Math.round(new Date().getTime() / 1000) + (ttl * 60);
        let data = '';
        try {
            data = JSON.stringify({data: value, expire: expTime});
            localStorage.setItem(key, data);
        } catch (e) {
            console.error('LocalStorage.set(' + key + ',' + value + ',' + ttl + ') =>', e);
        }
    }

    public clear() {
        setTimeout(() => {
            localStorage.clear();
        }, 100);

    }

    public remove(key: string) {
        setTimeout(() => {
            localStorage.removeItem(key);
        }, 100);
    }
}
