import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { AuthService } from '../@services/auth.service';

@Component({
    selector   : 'app-reseller-login',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss']
    
})
export class LoginComponent implements OnInit {
    public credentials: any = {};

    @ViewChild('loginForm') loginForm;

    constructor(private messageService: MessageService, private authService: AuthService, private router: Router) {
    }

    onSubmit() {
        this.authService.resellerLogin(this.credentials).subscribe((res) => {
            this.showMessage('success', 'Başarılı', 'Anasayfaya yönlendiriliyorsunuz.');
            this.loginForm.resetForm();
            this.router.navigate(['/pages/dashboard']);
        }, (err) => {
            this.showMessage('error', err.error.code, err.error.message);
        });
    }

    private showMessage(severity, summary, detail) {
        this.messageService.clear();
        this.messageService.add({
            severity,
            summary,
            detail
        });
    }

    ngOnInit() {
    }
}
