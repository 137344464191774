import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router,
    RouterStateSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
import { UserInterface } from '../../@core/modals';
import { map, take } from 'rxjs/operators';
import { UserType } from 'src/app/@core/modals/user-type';

@Injectable()
export class ResellerAuthGuard implements CanActivate, CanActivateChild, CanLoad {
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(childRoute, state);
    }

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.user.pipe(take(1), map((user: UserInterface) => {

            if (user.userType === UserType.ADMIN) {
                return true;
            } else {
                // this.router.navigate(['/rsl']);
                return false;
            }
        }));
    }

    canLoad(route: Route) {
        return this.authService.user.pipe(take(1), map((user: UserInterface) => {
            return user ? user.userType === UserType.ADMIN : false;
        }));

    }
}
