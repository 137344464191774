import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private authService: AuthService;

    constructor(private injector: Injector, private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.authService = this.injector.get(AuthService);

        req = this.setAuthHeader(req);

        return next.handle(req).pipe(map((event) => {
            return this.handleResponse(event);
        }), catchError((err) => {
            return this.handleError(err);
        }));
    }

    private setAuthHeader(req: HttpRequest<any>) {
        const token = this.authService.getAuthorizationToken();

        if (token) {
            req = req.clone({
                headers: req.headers.set('Authorization', token)
            });
        }
        return req;
    }

    private saveAuthToken(event: HttpResponse<any>) {
        if (event.headers.get('Authorization')) {
            this.authService.setAuthorizationToken(event.headers.get('Authorization'));
        }
    }

    private setContentType(req: HttpRequest<any>) {
        req = req.clone({
            headers: req.headers.set('Content-Type', 'application/json')
        });
        return req;
    }

    private handleResponse(event: HttpEvent<any>) {
        if (event instanceof HttpResponse) {
            this.saveAuthToken(event);
        }
        return event;
    }

    private handleError(err) {
        const { meta, data } = err.error;
    
        if (meta?.httpStatusCode === 401) {
            const user = this.authService.getLocalUserData();
            this.authService.purgeAuth();
            setTimeout(() => {
                if (!user) {
                    window.location.replace('/');
                } else {
                    window.location.replace('/auth');
                }
            }, 250);
        }
    
        const error = {
            code: meta?.errorCode,
            message: meta?.errorMessage,
            errors: data
        };
    
        return throwError({ error });
    }
    
}
