import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class PermissionService {

    constructor(private api: ApiService) {
    }

    getPermissions(query?: any) {
        return this.api.get('admin/permission', query);
    }

    getAllPermissions() {
        return this.api.get('admin/permission/all');
    }

    insert(permission: any) {
        return this.api.post('admin/permission', permission);
    }

    update(id: any, permission: any) {
        return this.api.post('admin/permission/' + id, permission);
    }

    delete(id: number | any) {
        return this.api.delete('admin/permission/' + id);
    }

    getMenu() {
        return this.api.get('admin/permission/menu');
    }
    
    getResellerMenu() {
        return this.api.get('reseller/permission/menu');
    }
}