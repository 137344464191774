<ng-container *ngIf="visible">
    <div [id]="id" #el>
        <p-dialog [(visible)]="visible" [modal]="modal" [blockScroll]="blockScroll" [style]="style" [appendTo]="appendTo"
                  [contentStyle]="contentStyle"
                  (visibleChange)="close($event)">
            <p-header>
                <ng-content select="p-header"></ng-content>
            </p-header>
            <div body-scroll-lock-ignore>
                <ng-content></ng-content>
            </div>
        </p-dialog>
    </div>
</ng-container>
