import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {SessionService} from '../../@core/services';
import {JwtService, LocalStorageService} from '../../@utils/services';
import {Router} from '@angular/router';
import {Admin, Reseller, UserInterface} from '../../@core/modals';
import {map} from 'rxjs/operators';
import { UserType } from 'src/app/@core/modals/user-type';
import { Employee } from 'src/app/@core/modals/employee';

@Injectable()
export class AuthService {
    private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
    public isAuthenticated = this.isAuthenticatedSubject.asObservable();
    private userSubject = new ReplaySubject<any | UserInterface>(1);
    public user = this.userSubject.asObservable();

    constructor(private sessionService: SessionService, private jwt: JwtService, private router: Router, private localStorageService: LocalStorageService) {

    }

    /**
     *
     * @param credentials
     * @returns {Observable<any>}
     */
    public login(credentials, type) {
        return this.sessionService.login(credentials, type).pipe(map((res) => {
            const {data, token} = res;
            this.setAuthorizationToken(token);
            this.setAuth(type, data);
            return data;
        }));
    }

    public logout(type) {
        return this.sessionService.logout(type).pipe(map((res) => {
            this.purgeAuth();
            return res;
        }));
    }

    purgeAuth() {
        this.jwt.destroyToken();
        this.isAuthenticatedSubject.next(false);
        this.userSubject.next({});
        this.localStorageService.remove('user');
    }

    setAuth(type, user) {
        if (type === UserType.ADMIN) {
            user = new Admin(user);
        } else if (type === UserType.RESELLER) {
            user = new Reseller(user);
        }
        else if (type === UserType.EMPLOYEE) {
            user = new Employee(user);
        }
        this.sessionService.setPath(type);
        this.localStorageService.set('user', user);
        this.isAuthenticatedSubject.next(true);
        this.userSubject.next(user);
    }

    getLocalUserData(): UserInterface {
        return this.localStorageService.get('user');
    }

    getUser() {
        return this.user;
    }

    checkAuth() {
        if (this.jwt.getToken()) {
            const user: UserInterface = this.localStorageService.get('user');
            if (!user) {
                this.purgeAuth();
                return;
            }
            this.refreshUser(user);
        } else {
            this.purgeAuth();
        }
    }

    setAuthorizationToken(token: string) {
        this.jwt.saveToken(token);
    }

    getAuthorizationToken() {
        let authToken = null;
        if (this.jwt.getToken()) {
            authToken = 'Bearer ' + this.jwt.getToken();
        }
        return authToken;
    }

    resellerLogin(credentials: any) {
        return this.sessionService.login(credentials, UserType.RESELLER).pipe(map((res) => {
            const {data,token} = res;
            this.setAuthorizationToken(token);
            this.setAuth(UserType.RESELLER, data);
            return data;
        }));
    }

    employeeLogin(credentials: any) {
        return this.sessionService.login(credentials, UserType.EMPLOYEE).pipe(map((res) => {
            const {data,token} = res;
            this.setAuthorizationToken(token);
            this.setAuth(UserType.EMPLOYEE, data);
            return data;
        }));
    }

    refreshUser(user: UserInterface) {
        this.sessionService.refresh(user.userType).subscribe((res) => {
            this.setAuth(user.userType, res.data);
        }, (err) => {
            this.purgeAuth();
        });
    }
}
