import { UserType } from './user-type';
import {UserInterface} from './user.interface';

export class User implements UserInterface {
    id: number;
    createdAt: string;
    email: string;
    userType: UserType;
    isWeb: boolean;
    mobilePhone: any;
    name: string;
    firstName: string;
    surName: string;
    accountId: any;
    counrtyId: any;
    currencyId: any;
    identityNo: any;
    lang: any;
    lastLogin: any;
    notificationStatus: any;
    phoneStatus: any;
    resellerId: any;
    parentId: any;
    password: string;
    phone: any;
    status: any;
    updatedAt: string;
    username: string;
    type: string;

    constructor(data?: any) {
        Object.assign(this, data);
    }


}
