import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class LogService {

    constructor(private api: ApiService) {
    }

    getLogs(query?: any) {
        return this.api.get(this.api.path + '/logger', query);
    }
}
