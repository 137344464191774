import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ScrollPanel } from 'primeng/scrollpanel';
import { PagesComponent } from '../../../pages/pages.component';
import { TranslateService } from '@ngx-translate/core';
import { CryptoService, EmailsService, PermissionService, ResellerService } from '../../../@core/services';
import { AppConfigService } from '../../../app.config.service';
import {  EventEmitter, Output } from '@angular/core';
import { UserType } from 'src/app/@core/modals/user-type';
@Component({
    selector: 'app-menu',
    templateUrl: 'menu.component.html',
})

export class MenuComponent implements OnInit, AfterViewInit {
    isMenuExpanded = true;
    model: MenuItem[];
    active?: boolean;
    domains: any;
    cryptoActive: any;
    campaignActive: any = [];
    languages = [];
    showSubMenu?: boolean;
    @Output() toggleSidebarEvent = new EventEmitter<void>();
    sidebarExpanded = true;
    @ViewChild('scrollPanel', { static: true }) layoutMenuScrollerViewChild: ScrollPanel;

    constructor(public app: PagesComponent,
        public translate: TranslateService,
        private permissionService: PermissionService,
        private configService: AppConfigService,
        private resellerService: ResellerService,
        private cryptoService: CryptoService,
        private emailService: EmailsService,
        public config: AppConfigService,) {
    }

    ngAfterViewInit() {

        setTimeout(() => {
            this.layoutMenuScrollerViewChild.moveBar();
        }, 100);
    }

      public toggleMenu(): void {
        console.log('Toggle menu clicked');
        this.isMenuExpanded = !this.isMenuExpanded;
      }
      
    ngOnInit() {
        const languages = this.config.getLanguages();
        this.languages = languages.map((lang) => {
            return {
                label: lang,
                value: lang
            };
        });
        if (this.app.user.userType === UserType.ADMIN) {
            this.loadAdminMenu();
        } else if(this.app.user.userType === UserType.RESELLER) {
            this.resellerService.getResellerDomains(this.app.user.id).subscribe(res => {
                this.domains = res.data.length;
            });
            this.cryptoService.getCryptoCurrenciesResller(this.app.user.id).subscribe(res => {
                this.cryptoActive = res.data.data ? true : false;
            }, (err) => {
                if (err.error.code === 2002) {
                    this.cryptoActive = false;
                }


            });
            this.emailService.getResellerGateway(this.app.user.id).subscribe(
                (data) => {
                    this.campaignActive = data.data;
                }
            );

            setTimeout(() => {
                this.loadResellerMenu();
            }, 1000);


        }

    }
    changeLanguage(userLang: any) {
        this.translate.use(userLang);
        localStorage.setItem('lang', userLang);
    }
    // toggleMenu(): void {
    //     this.sidebarExpanded = !this.sidebarExpanded;
    //     this.sidebarToggled.emit(this.sidebarExpanded);
    //  }
    public loadResellerMenu() {
        this.model = [
            { label: this.translate.instant('Dashboard'), icon: 'fa fa-fw fa-home', routerLink: ['/pages'] },
            {
                label: this.translate.instant('Hesap'),
                icon: 'fa fa-fw fa-home',
                routerLink: ['/pages/reseller/' + this.app.user.id]
            },
            {
                label: this.translate.instant('Bayi'),
                icon: 'fa fa-fw fa-industry',
                routerLink: ['/pages/reseller'],
                visible: this.app.user.isSupervisor
            },
            {
                label: this.translate.instant('Üyeler'),
                icon: 'fa fa-fw fa-user',
                routerLink: ['/pages/membership'],
                visible: this.app.user.isSupervisor || this.app.user.option.canCreateUser
            },
            {
                label: this.translate.instant('Filiale'),
                icon: 'fa fa-fw fa-coffee',
                routerLink: ['/pages/filiale'],
                visible: this.app.user.isSupervisor || this.app.user.option.canCreateFiliale
            },
            {
                label: this.translate.instant('Terminal'),
                icon: 'fa fa-fw fa-computer',
                routerLink: ['/pages/terminal'],
                visible: this.app.user.isSupervisor || this.app.user.option.canCreateTerminal
            },
            {
                label: this.translate.instant('Kuponlar'),
                icon: 'fa fa-fw fa-futbol-o',
                routerLink: ['/pages/coupon/sport-bet'],

            },
            {
                label: this.translate.instant('Oyun Bahisleri'),
                icon: 'fa fa-fw fa-database',
                routerLink: ['/pages/coupon/game-bet'],
                visible: !this.app.user.isWeb
            },
            {
                label: this.translate.instant('Muhasebe'),
                icon: 'fa fa-fw fa-money',
                //visible: (this.app.user.parentId === 0 && this.app.user.isWeb),
                items: [
                    {
                        label: this.translate.instant('Yatırılanlar'),
                        icon: 'fa fa-fw fa-download',
                        routerLink: ['/pages/accounting/deposit']
                    },
                    {
                        label: this.translate.instant('Çekilenler'),
                        icon: 'fa fa-fw fa-upload',
                        routerLink: ['/pages/accounting/withdraw']
                    },
                    {
                        label: this.translate.instant('Crypto'),
                        icon: 'fa fa-fw fa-money',
                        routerLink: ['/pages/crypto/balance'],
                        visible: this.cryptoActive
                    },
                    // {
                    //     label     : this.translate.instant('Hesap Yönetimi'),
                    //     icon      : 'fa fa-fw fa-cogs',
                    //     routerLink: ['/pages/accounting/management/payment-method-account']
                    // }
                ]
            },
            {
                label: this.translate.instant('CMS'),
                icon: 'fa fa-fw fa-square',
                items: [
                    {
                        label: this.translate.instant('Pages'),
                        icon: 'fa fa-w fa-file',
                        routerLink: ['/pages/pages/management'],
                        visible: this.domains > 0 ? true : false

                    },
                    {
                        label: this.translate.instant('Email'),
                        icon: 'fa fa-fw fa-square',
                        items: [
                            {
                                label: this.translate.instant('Template'),
                                icon: 'fa fa-w fa-file',
                                routerLink: ['/pages/pages/management'],
                                visible: this.domains > 0 ? true : false
        
                            },
                        ]
                        },
                    {
                        label: this.translate.instant('Campaign'),
                        icon: 'fa fa-w fa-table',
                        routerLink: ['/pages/emails/campaigns/campaign'],
                        visible: this.campaignActive.length > 0 ? true : false
                    },
                    {
                        label: this.translate.instant('Slider'),
                        icon: 'fa fa-w fa-table',
                        routerLink: ['/pages/marketing/banner'],
                        visible: this.domains > 0 ? true : false
                    },
                    {
                        label: this.translate.instant('Footer'),
                        icon: 'fa fa-w fa-table',
                        routerLink: ['/pages/system/footer/footer'],
                        visible: this.domains > 0 ? true : false

                    }
                ]
            },
            // {
            //     label: this.translate.instant('İstatistikler'),
            //     icon: 'fa fa-fw fa-bar-chart',
            //     routerLink: ['/pages/statistics']
            // },
            {
                label: this.translate.instant('Pazarlama'),
                icon: 'fa fa-fw fa-shopping-bag',
                visible: (this.app.user.parentId === 0 && this.app.user.isWeb),
                items: [
                    {
                        label: this.translate.instant('Promosyonlar'),
                        icon: 'fa fa-fw fa-shopping-bag',
                        routerLink: ['/pages/marketing/promotion']
                    },
                    {
                        label: this.translate.instant('Slider'),
                        icon: 'fa fa-fw fa-commenting-o',
                        routerLink: ['/pages/marketing/banner']
                    }
                ]

            },
            {
                label: this.translate.instant('Destek'),
                icon: 'fa fa-w fa-comment',
                routerLink: ['/pages/support']
            },

        ];
        if (this.configService.isWeb) {
            this.model.push({
                label: this.translate.instant('Yardım'),
                icon: 'fa fa-fw fa-question-circle-o ',
                routerLink: ['/pages/guide']
            });
        }
    }

    private loadAdminMenu() {
        this.model = [
            { label: this.translate.instant('Dashboard'), icon: 'fa fa-fw fa-home', routerLink: ['/pages'] }
        ];
        this.permissionService.getMenu().subscribe((data) => {
            this.model = [...this.model, ...data.data];

        }, (err) => {

        });

    }
}
