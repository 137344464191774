import { Injectable } from '@angular/core';
// @ts-ignore
import format from 'date-fns/format';


@Injectable()
export class DateTimeService {
    userDateTimeFormat  = 'DD-MM-YYYY HH:mm:ss';
    eventDateTimeFormat = 'DD/MM HH:mm';
    eventDateNormal = 'DD.MM.YYYY';
    couponTimeFormat    = 'DD/MM HH:mm:ss';
    dateFormat          = 'DD/MM';
    hourFormat          = 'HH:mm';

    constructor() {
    }

    formatDate(date, dateFormat?) {
        if (date && date !== '0000-00-00 00:00:00') {
            return format(date + 'Z00:00', dateFormat ? dateFormat : 'YYYY-MM-DD HH:mm:ss');
        }
        return '';
    }

    formatDateOnlyYearMonth(date, dateFormat?) {
        if (date && date !== '0000-00-00 00:00:00') {

            return format(date+ 'Z00:00', dateFormat ? dateFormat : 'YYYY-MM');
        }
        return '';
    }

    now(dateFormat?) {
        return format(new Date(), dateFormat ? dateFormat : 'YYYY-MM-DD');
    }
}
