import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class StatisticsService {

    constructor(private api: ApiService) {
    }

    getResellerStatistics(query?: any) {
        return this.api.get(this.api.path + '/stats/reseller', query);
    }

    getUserStatistics(query?: any) {
        return this.api.get(this.api.path + '/stats/user', query);
    }
}
