import { UserType } from './user-type';
import { UserInterface } from './user.interface';

export class Employee implements UserInterface {
    id: number;
    name: string;
    username: string;
    password: any;
    email: string;
    mobilePhone: any;
    phone: any;
    status: number;
    createdAt: string;
    updatedAt: string;

    isWeb: boolean;
    type: string;
    balance: number;
    lastDeposit: number;
    userType: UserType = UserType.EMPLOYEE;
    isSupervisor: boolean;

    constructor(data: {}) {
        Object.assign(this, data);
        this.isWeb = this.type ? true : false;
    }


}
