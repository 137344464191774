import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class RoleService {

    constructor(private api: ApiService) {
    }

    getRoles(query?: any) {
        return this.api.get('admin/role', query);
    }

    insert(data: any) {
        return this.api.post('admin/role', data);
    }

    update(id: any, data: any) {
        return this.api.post('admin/role/' + id, data);
    }

    delete(id: number | any) {
        return this.api.delete('admin/role/' + id);
    }

    getRolePermissions(id: any) {
        return this.api.get('admin/role/' + id + '/tree');
    }

    saveRolePermission(data: { roleId: any; permissionId: any[] }) {
        return this.api.post('admin/role/permission', data);
    }
}