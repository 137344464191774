import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class SystemConfigService {

    constructor(private api: ApiService) {
    }

    getConfigs(query?: any) {
        return this.api.get('admin/systemConfig', query);
    }

    insert(data: any) {
        return this.api.post('admin/systemConfig', data);
    }

    update(id: any, data: any) {
        return this.api.post('admin/systemConfig/' + id, data);
    }

    delete(id: number | any) {
        return this.api.delete('admin/systemConfig/' + id);
    }

    getDomains(query?: any) {
        return this.api.get('admin/domain', query);
    }
    getFooterFields(query?: any) {
        return this.api.get('admin/domain', query);
    }
    insertDomain(data: any) {
        return this.api.post('admin/domain', data);
    }

    updateDomain(id: any, data: any) {
        return this.api.post('admin/domain/' + id, data);
    }

    deleteDomainWithPromotion(id: number | any) {
        return this.api.delete('admin/domain/' + id);
    }

    deleteOnlyDomain(id: number | any) {
        return this.api.delete('admin/deleteOnlyDomain/' + id);
    }

    getResellers() {
        return this.api.post('admin/domain-resellers');
    }


    getPromotions(query?: any) {
        return this.api.get('admin/promotion', query);
    }
    insertPromotion(data: any) {
        return this.api.post('admin/promotion', data);
    }

    updatePromotion(id: any, data: any) {
        return this.api.post('admin/promotion/' + id, data);
    }

    deletePromotion(id: number | any) {
        return this.api.delete('admin/promotion/' + id);
    }
    getDomainPromotion() {
        return this.api.post('admin/promotions-domain');
    }


}
