import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfigService } from '../../../app.config.service';
import { LoaderService } from '../../services';

@Component({
    selector   : 'app-loader',
    templateUrl: './loader.component.html'
})
export class LoaderComponent implements OnInit {
    show = false;
    private subscription: Subscription;

    constructor(private loaderService: LoaderService, public config: AppConfigService) {
    }

    ngOnInit() {

        this.subscription = this.loaderService.loaderState.subscribe((state) => {
            this.show = state.show;
        });
    }

}
