import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable()
export class SupportMessageService {

    constructor(private api: ApiService) {  }

    getResellerMessages(id:any, query?: any){
        return this.api.get(this.api.path + '/reseller-user-messages/'  ,{...query,...{userId: id}});
    }
    saveResllerMessages(id:any,data:any){
        return this.api.post(this.api.path + '/reseller-to-user-message/' + id, data);
    }
    getTicketsCount(id:any){
        return this.api.get(this.api.path + '/ticket-counts/' + id);
    }
    getTicketsToal(id:any){
        return this.api.get(this.api.path + '/all-ticket-counts/' + id);
    }
    saveForwardMessageToAdmin(id:any,data:any){
        return this.api.post(this.api.path + '/forward-to-admin/' + id, data);
    }
    getAllAdminMessages(query?: any){
        return this.api.get(this.api.path + '/adminMessage',query);
    }
    saveAdminMessages(id:any,data:any){
        return this.api.post(this.api.path + '/message-to-user/' + id, data);
    }
    getAdminTicketsCount(){
        return this.api.get(this.api.path + '/ticket-counts');
    }
    getAdminTicketsTotal(){
        return this.api.get(this.api.path + '/all-ticket-counts');
    }
    saveForwardMessageToReseller(id:any,data:any){
        return this.api.post(this.api.path + '/forward-to-reseller/' + id, data);
    }
    deleteSupportTicket(id: number | any) {
        return this.api.delete(this.api.path + '/message/' + id + '/deleteMessage');
    }
}