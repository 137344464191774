<app-loader></app-loader>
<p-toast [baseZIndex]="1000">
    <ng-template let-message pTemplate="message">
    <span class="ui-toast-icon pi"
          [ngClass]="{'pi-info-circle': message.severity == 'info', 'pi-exclamation-triangle': message.severity == 'warn',
                            'pi-times': message.severity == 'error', 'pi-check' :message.severity == 'success'}"></span>
        <div class="ui-toast-message-text-content">
            <div class="ui-toast-summary">{{message.summary | translate}}</div>
            <div class="ui-toast-detail">{{message.detail | translate}}</div>
        </div>
    </ng-template>
</p-toast>
<p-confirmDialog acceptLabel="{{'Evet' | translate}}" rejectLabel="{{'Hayır' | translate}}"></p-confirmDialog>
<router-outlet></router-outlet>