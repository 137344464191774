import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
import { map, take } from 'rxjs/operators';

@Injectable()
export class NoAuthGuard implements CanActivate, CanLoad {

    constructor(private authService: AuthService, private router: Router) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.isAuthenticated.pipe(take(1), map((bool) => !bool));
    }

    canLoad(route: Route) {
        return this.authService.isAuthenticated.pipe(take(1), map((bool) => {
            if (bool) {
                this.router.navigate(['/pages']);
            }
            return !bool;
        }));
    }
}
