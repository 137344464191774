<div class="layout-footer " style="background-color:#0f172a;">
       
    <div class="p-grid">
        <div class="p-col-6"><a class="logo-container" href="/">
            <img src="../../../../assets/images/Backoffice.png" alt="babylon-layout" class="Logo">
        </a></div>
        <div class="p-col-6 footer-icons"><a routerLink="/"><i class="pi pi-home"></i></a><a href="#"><i class="pi pi-globe"></i></a><a href="#"><i
            class="pi pi-envelope"></i></a></div>
    </div>
</div>
