import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class CardService {

    constructor(private api: ApiService) {
    }

    getCardGroup(query?: any) {
        return this.api.get('admin/card', query);
    }

    insert(data: any) {
        return this.api.post('admin/card', data);
    }

    getCards(cardGroupId: number, query?: any) {
        return this.api.get('admin/card/' + cardGroupId + '/card', query);
    }

    download(cardGroupId: number) {
        return this.api.get('admin/card/' + cardGroupId + '/download');
    }
}
