<ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
    <ng-container *ngIf="child.visible != false">
        <li [ngClass]="{'layout-root-menuitem':root ,'active-menuitem': isActive(i)}"
            (mouseleave)="activeIndex = -1">
            <a [href]="child.url||''" (click)="itemClick($event,child,i)"
               (mouseenter)="itemHover($event,child,i)"
               *ngIf="!child.routerLink" [attr.target]="child.target">
                <i class="layout-menuitem-icon" [ngClass]="child.icon" *ngIf="child.icon"></i>
                <span class="layout-menuitem-text">{{child.label | translate}}</span>
                <i class="pi pi-fw pi-angle-down layout-submenu-toggler"
                   *ngIf="child.items"></i>
            </a>
            <a (click)="itemClick($event,child,i)" *ngIf="child.routerLink"
               [target]="child.target"
               [routerLink]="child.routerLink" routerLinkActive="active-route"
               [routerLinkActiveOptions]="{exact: true}">
                <i class="layout-menuitem-icon" [ngClass]="child.icon" *ngIf="child.icon"></i>
                <span class="layout-menuitem-text">{{child.label | translate}}</span>
                <i class="pi pi-fw pi-angle-down layout-submenu-toggler"
                   *ngIf="child.items"></i>
            </a>
            <ul app-submenu [item]="child" *ngIf="child.items"
                [@children]="isActive(i) ? 'visible' : 'hidden'"></ul>
        </li>
    </ng-container>
</ng-template>
