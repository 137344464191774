import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class TransactionService {

    constructor(private api: ApiService) {
    }

    getTransactionTypes() {
        return this.api.get('admin/transaction/type');
    }

    getTransactionTypeCategories() {
        return this.api.get('admin/transaction/category');
    }

    getTransactions(query?: any) {
        return this.api.get('admin/transaction', query);
    }
}