import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class PageService {

  constructor(private api: ApiService) { }


  getAnnouncement(query?: any) {
    return this.api.get(this.api.path + '/announcement', query);
  }
  getAnnouncementReOrder(data: any) {
    return this.api.post(this.api.path + '/announcement/reOrder' , data);
  }
  insertAnnouncement(data: any) {
    return this.api.post(this.api.path + '/announcement', data);
  }

  updateAnnouncement(id: any, data: any) {
    return this.api.post(this.api.path + '/announcement/' + id, data);
  }

  deleteAnnouncement(id: any) {
    return this.api.delete(this.api.path + '/announcement/' + id);
  }



  getPages(query?: any) {
    return this.api.get(this.api.path + '/page', query);
  }
  insertPage(data: any) {
    return this.api.post(this.api.path + '/page', data);
  }

  updatePage(id: any, data: any) {
    return this.api.post(this.api.path + '/page/' + id, data);
  }

  deletePage(id: any) {
    return this.api.delete(this.api.path + '/page/' + id);
  }

  insertPageReseller(data: any) {
    return this.api.post(this.api.path + '/page', data);
  }

  updatePageReseller(id: any, data: any) {
    return this.api.post(this.api.path + '/page/' + id, data);
  }
  getPagesReseller(id: any, query?: any,) {
    return this.api.get(this.api.path + '/page/' + id + '/', query);
  }

  deletePageReseller(id: any) {
    return this.api.delete(this.api.path + '/page/' + id);
  }

  getAllresellers() {
    return this.api.get(this.api.path + '/reseller/getAllReseller');
  }

}
