import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class CountryService {

    constructor(private api: ApiService) {
    }

    getCountries(query?: any) {
        return this.api.get('admin/country', query);
    }

    insert(data: any) {
        return this.api.post('admin/country', data);
    }

    update(id: any, data: any) {
        return this.api.post('admin/country/' + id, data);
    }

    delete(id: number | any) {
        return this.api.delete('admin/country/' + id);
    }
}