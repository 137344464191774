import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class MediaService {

    constructor(private api: ApiService) {
    }

    getMedia(query?: any) {
        return this.api.get(this.api.path + '/media', query);
    }

    insert(data: any) {
        return this.api.postFile(this.api.path + '/media', data);
    }

    update(id: any, data: any) {
        return this.api.post(this.api.path + '/media/' + id, data);
    }

    delete(id: number | any) {
        return this.api.delete(this.api.path + '/media/' + id);
    }

    updateTranslate(id: any, data: any) {
        return this.api.post(this.api.path + '/media/translate/' + id, data);
    }

    insertTranslate(data: any) {
        return this.api.post(this.api.path + '/media/translate', data);
    }

    getTranslate(query?: any) {
        return this.api.get(this.api.path + '/media/translate', query);
    }

    getOutcomes(query?: any) {
        return this.api.get(this.api.path + '/media/outcome', query);
    }

    updateOutcome(id: any, data: any) {
        return this.api.post(this.api.path + '/media/outcome/' + id, data);
    }

    getLiveOutcomes(query?: any) {
        return this.api.get(this.api.path + '/media/live-outcome', query);
    }

    updateLiveOutcome(id: any, data: any) {
        return this.api.post(this.api.path + '/media/live-outcome/' + id, data);
    }
}
