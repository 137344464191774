import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class CasinoService {

    constructor(private api: ApiService) {
    }

    getAllProviders() {
        return this.api.get(this.api.path + '/casino/provider/all');
    }

    getProviders(query?: any) {
        return this.api.get(this.api.path + '/casino/provider', query);
    }

    updateProvider(id: any, data: any) {
        return this.api.post(this.api.path + '/casino/provider/' + id, data);
    }

    getAllCasino() {
        return this.api.get(this.api.path + '/casino/all');
    }

    getCasino(query?: any) {
        return this.api.get(this.api.path + '/casino/', query);
    }

    updateCasino(id: any, data: any) {
        return this.api.post(this.api.path + '/casino/' + id, data);
    }

    getCategories(query?: any) {
        return this.api.get(this.api.path + '/casino/category', query);
    }

    insertCategory(data: any) {
        return this.api.post(this.api.path + '/casino/category/', data);
    }

    updateCategory(id: any, data: any) {
        return this.api.post(this.api.path + '/casino/category/' + id, data);
    }

    getCasinoCategories(categoryId) {
        return this.api.get(this.api.path + '/casino/category/' + categoryId + '/providerGames');
    }

    deleteCasinoCategory(categoryId) {
        return this.api.delete(this.api.path + '/casino/category/' + categoryId + '/delete');
    }

    saveCasinoCategories(id: any, data: any) {
        return this.api.post(this.api.path + '/casino/category/' + id + '/providerGame/', data);
    }

    getCasinoBets(query?: any) {
        return this.api.get(this.api.path + '/casinoBet', query);
    }

    duplicateCategories(id: number) {
        return this.api.post(this.api.path + '/casino/category/' + id + '/duplicate');
    }

    getCustomProviders(query?: any) {
        return this.api.get(this.api.path + '/casino/providerCategory', query);
    }

    insertCustomCategory(data: any) {
        return this.api.post(this.api.path + '/casino/providerCategory', data);
    }

    updateCustomCategory(id: any, data: any) {
        return this.api.post(this.api.path + '/casino/providerCategory/' + id, data);
    }

    deleteCustomCategory(categoryId) {
        return this.api.delete(this.api.path + '/casino/providerCategory/' + categoryId + '/delete');
    }
}
