import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class CurrencyService {

    constructor(private api: ApiService) {
    }

    getCurrencies(query?: any) {
        return this.api.get(this.api.path + '/currency', query);
    }

    insert(data: any) {
        return this.api.post(this.api.path + '/currency', data);
    }

    update(id: any, data: any) {
        return this.api.post(this.api.path + '/currency/' + id, data);
    }

    delete(id: number | any) {
        return this.api.delete(this.api.path + '/currency/' + id);
    }
}
