import {User} from './user';
import {Odds} from './odds';

export class Coupon {
    id: any;
    gain: any;
    count: any;
    winCount: any;
    loseCount: any;
    createdAt: any;
    odds: Odds[];
    potentialGain: any;
    stake: any;
    status: any;
    systems: any;
    totalOdd: any;
    totalStake: any;
    type: any;
    user: User;
    isPaid: any;
    paidDate: any;
    barcode: any;
    receiptId: any;
    tax:any;
    vat:any;
    bonus:any;

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
            if (data.user) {
                this.user = new User(data.user);
            }
            if (data.odds) {
                this.odds = data.odds.map((odds) => {
                    return new Odds(odds);
                });
            }
        }
    }
}
