import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthComponent } from './auth.component';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { LayoutModule } from '../@layout/layout.module';
import { AuthService } from './@services/auth.service';
import { AuthInterceptor } from './@services/auth.interceptor';
import { AuthGuard } from './@services/auth-guard.service';
import { NoAuthGuard } from './@services/no-auth-guard.service';
import { ResellerAuthGuard } from './@services/reseller-auth-guard.service';
import { WebResellerAuthGuard } from './@services/web-reseller-auth-guard.service';

@NgModule({
    imports     : [CommonModule, FormsModule, LayoutModule, AuthRoutingModule],
    exports     : [],
    declarations: [AuthComponent, LoginComponent],
    providers   : []
})
export class AuthModule {
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule : AuthModule,
            providers: [
                AuthService,
                {
                    provide : HTTP_INTERCEPTORS,
                    useClass: AuthInterceptor,
                    multi   : true
                },
                NoAuthGuard,
                AuthGuard,
                ResellerAuthGuard,
                WebResellerAuthGuard
            ]
        };
    }
}
