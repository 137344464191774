import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class BannerService {

    constructor(private api: ApiService) {
    }

    getBanners(query?: any) {
        return this.api.get(this.api.path + '/banner', query);
    }
    getResellerBanners(resellerId?: any, query?: any) {
        return this.api.get(this.api.path + '/banner/' + resellerId , query);
    } 

    insert(data: any) {
        return this.api.post(this.api.path + '/banner', data);
    }

    update(id: any, data: any) {
        return this.api.post(this.api.path + '/banner/' + id, data);
    }

    delete(id: number | any) {
        return this.api.delete(this.api.path + '/banner/' + id);
    }
}
