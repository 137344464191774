import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-form-message',
    templateUrl: 'form-message.component.html'
})

export class FormMessageComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }
}