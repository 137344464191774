import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { Table } from "primeng/table";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { AppConfigService } from "../../../app.config.service";

@Component({
    selector: "table-filter",
    templateUrl: "./table-filter.component.html",
})
export class TableFilterComponent implements OnInit, OnDestroy {
    @Input() filter: any;
    @Input() table: Table;
    @Input() last = false;
    @ViewChild("multi") multi;
    @Output() onChange = new EventEmitter();
    data = [];
    operators = [
        { label: ">", value: "gt" },
        { label: "<", value: "lt" },
    ];
    maxDate;
    rangeDates: any;
    yearRange: any;
    ngUnsubscribe = new Subject();
    now = new Date();
    multiSelected: any = [];
    dataTypes = {
        yesNo: [
            { label: this.translate.instant("Evet"), value: 1 },
            { label: this.translate.instant("Hayır"), value: 0 },
        ],
        activePassive: [
            { label: this.translate.instant("Aktif"), value: 1 },
            { label: this.translate.instant("Pasif"), value: 0 },
        ],
        passiveActive: [
            { label: this.translate.instant("Aktif"), value: 0 },
            { label: this.translate.instant("Pasif"), value: 1 },
        ],
        configTypes: [
            { label: this.translate.instant("common"), value: "common" },
            {
                label: this.translate.instant("system"),
                value: "system",
            },
        ],
        optionDataTypes: [
            { label: this.translate.instant("string"), value: "string" },
            { label: this.translate.instant("number"), value: "number" },
            { label: this.translate.instant("boolean"), value: "boolean" },
            { label: this.translate.instant("media"), value: "media" },
            { label: this.translate.instant("json"), value: "json" },
        ],
        paymentStatuses: [
            { label: this.translate.instant("Tamamlandı"), value: 1 },
            {
                label: this.translate.instant("Bekleyen"),
                value: 0,
            },
            { label: this.translate.instant("İptal"), value: -1 },
        ],
        receiptStatuses: [
            { label: this.translate.instant("Ödendi"), value: 1 },
            {
                label: this.translate.instant("Bekleyen"),
                value: 0,
            },
            { label: this.translate.instant("İptal"), value: -1 },
        ],
        userStatuses: [
            { label: this.translate.instant("Aktif"), value: "active" },
            { label: this.translate.instant("Bekleyen"), value: "pending" },
            { label: this.translate.instant("Ban"), value: "ban" },
            { label: this.translate.instant("Pasif"), value: "passive" },
        ],
        optionPermission: [
            { label: this.translate.instant("root"), value: "root" },
            { label: this.translate.instant("sub"), value: "sub" },
            { label: this.translate.instant("all"), value: "all" },
        ],
        optionType: [
            { label: this.translate.instant("common"), value: "common" },
            { label: this.translate.instant("reseller"), value: "reseller" },
            { label: this.translate.instant("user"), value: "user" },
        ],
        gameProviders: [
            { label: "GrandX", value: "grandX" },
            { label: "Timeless", value: "timeless" },
            { label: "Gamingconstruct", value: "gamingconstruct" },
        ],
        logStatuses: [
            { label: "Info", value: 200 },
            { label: "Warning", value: 300 },
            { label: "Error", value: 400 },
        ],
        campaignReports: [
            { label: "Sms", value: 0 },
            { label: "Email", value: 1 },
        ],
        recipientStatus: [
            { label: "Subscribe", value: 1 },
            { label: "Unsubscribe", value: 0 },
        ],
        recipientListStatus: [
            { label: "Active", value: 1 },
            { label: "Disable", value: 0 },
        ],
        withdrawStatuses: [
            { label: this.translate.instant("Bekleyen"), value: 1 },
            { label: this.translate.instant("Tamamlandı"), value: 2 },
            { label: this.translate.instant("İptal"), value: 3 },
            { label: this.translate.instant("İşlem"), value: 4 },
            { label: this.translate.instant("Talep"), value: 5 },
        ],
        depositStatuses: [
            { label: this.translate.instant("Tamamlandı"), value: 2 },
            { label: this.translate.instant("Bekleyen"), value: 1 },
            { label: this.translate.instant("İptal"), value: 3 },
        ],
    };
    selectAll = false;

    constructor(
        public configService: AppConfigService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        switch (this.filter.matchMode) {
            case "single":
            case "multi":
                if (this.filter.dataType) {
                    this.loadMultiData(this.filter.dataType);
                } else {
                    if (!this.filter.data) {
                        return;
                    }
                    this.checkSelected();
                    if (this.filter.data.hasOwnProperty("data")) {
                        this.data = this.filter.data.data;
                        return;
                    }
                    this.filter.data.observable.subscribe((data) => {
                        this.data = [];
                        console.warn(data.data);
                        if (data.data) {
                            let newData = [];
                            if (!this.filter.data.resellerSpecial) {
                                if (this.filter.data.startsWith) {
                                    this.data = [
                                        ...this.filter.data.startsWith,
                                    ];
                                }
                                newData = data.data.map((filter) => {
                                    if (!this.filter.data.translate) {
                                        return {
                                            label: filter[
                                                this.filter.data.label
                                            ],
                                            value: filter[
                                                this.filter.data.value
                                            ],
                                        };
                                    }
                                    return {
                                        label: filter[this.filter.data.label][
                                            this.translate.currentLang
                                        ],
                                        value: filter[this.filter.data.value],
                                    };
                                });
                            } else {
                                newData = this.buildResellerSpecial(data.data);
                            }

                            this.data = [...this.data, ...newData];
                        }
                    });
                }
                break;
            case "dateRange":
                const now = new Date();
                this.yearRange = "1970:2030";
                if (this.filter.maxDate) {
                    this.yearRange = "1970:" + now.getFullYear();
                    this.maxDate = new Date(
                        now.getFullYear(),
                        now.getMonth(),
                        now.getDate() + 1,
                        23,
                        59,
                        59,
                        999
                    );
                }
                if (this.filter.startDate) {
                    const startDate = this.getDate(this.filter.startDate);
                    this.rangeDates = [startDate];
                    this.setFilter(this.rangeDates, "dateRange");
                }
                break;
        }
        if (this.last && !this.table.lazyLoadOnInit) {
            this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
        }
    }

    private loadMultiData(dataType: any) {
        this.data = [];
        switch (dataType) {
            case "walletTypes":
            case "couponTypes":
            case "eventTypes":
            case "bonusTypes":
            case "couponStatuses":
            case "accountStatuses":
            case "statuses":
            case "bannerKeys":
            case "transactionTypeCategories":
            case "transactionTypes":
            case "userLimitTypes":
            case "rslProgressPaymentTypes":
            case "logCategories":
            case "rslTransactionTypes":
            case "empTransactionTypes":
            case "promotionTypes":
            case "eventStatuses":
                this.data = [...this.configService.getFilters(dataType)];
                this.checkSelected();
                break;
            case "yesNo":
            case "activePassive":
            case "passiveActive":
            case "logStatuses":
            case "campaignReports":
            case "FooterName":
            case "recipientStatus":
            case "recipientListStatus":
            case "optionDataTypes":
            case "configTypes":
            case "paymentStatuses":
            case "withdrawStatuses":
            case "depositStatuses":
            case "receiptStatuses":
            case "userStatuses":
            case "gameProviders":
                this.data = this.dataTypes[dataType];
                this.checkSelected();
                break;
            case "optionPermission":
                this.data = this.dataTypes[dataType];
                break;
            case "optionType":
                this.data = this.dataTypes[dataType];
                break;
        }
    }

    filterTable(inputValue, matchMode) {
        let value = inputValue;
        const field = this.filter.field;
    
        if (matchMode === 'dateRange' && value && Array.isArray(value)) {
            if (value.length === 2) {
                let startDate = new Date(value[0]);
                let endDate = new Date(value[1]);
    
                if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
                    console.error("Invalid date range provided:", value);
                    return;
                }
    
                startDate.setHours(0, 0, 0, 0);
    
                endDate.setHours(23, 59, 59, 999);
    
                value = [
                    startDate.toISOString(),
                    endDate.toISOString()
                ];
            }
    
            value = this.filter.join
                ? { prefix: this.filter.join, value: value }
                : value;
    
        } else {
            value = this.filter.join
                ? { prefix: this.filter.join, value: inputValue }
                : inputValue;
        }
    
        this.table.filter(value, field, matchMode);
        this.onChange.emit({ field, value });
    }
    
    
    
    

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.ngUnsubscribe.unsubscribe();
    }

    private getDate(days) {
        return new Date(
            this.now.getFullYear(),
            this.now.getMonth(),
            this.now.getDate() + +days
        );
    }

    private buildResellerSpecial(data) {
        const parent = { value: 0, label: "" };
        if (this.filter.selected && this.filter.selected.length) {
            parent.value = this.filter.selected[0].parentId;
        }
        return this.checkHasChildren(parent, data);
    }

    private checkHasChildren(parent: any, data: any) {
        const resellers = [];

        data.forEach((reseller) => {
            if (reseller.parentId === parent.value) {
                const item = {
                    label: parent.label + reseller.username + " => ",
                    value: reseller.id,
                };
                const result = this.checkHasChildren(item, data);
                item.label = this.trimEnd(item.label, " => ");
                resellers.push(item);
                if (result.length) {
                    result.forEach((res) => {
                        res.label = this.trimEnd(res.label, " => ");
                        resellers.push(res);
                    });
                }
            }
        });
        return resellers;
    }

    trimEnd(str, charList) {
        return str.replace(new RegExp("[" + charList + "]+$"), "");
    }

    selectChange($event: any[]) {
        const search = $event.map((x) => {
            return x.value;
        });
        this.filterTable(search, "in");
    }

    toggleSelectAll() {
        const search = [];
        if (!this.selectAll) {
            this.multiSelected = this.data.map((x) => {
                search.push(x.value);
                return x.value;
            });
        } else {
            this.multiSelected = [];
        }
        this.selectAll = !this.selectAll;

        this.filterTable(search, "in");
    }

    clearSelect($event: {}) {
        this.selectAll = false;
    }

    private checkSelected() {
        if (this.filter.selected && this.filter.selected.length) {
            this.multiSelected = [this.filter.selected[0].id];
            this.setFilter(this.multiSelected, "in");
        }
    }

    private setFilter(inputValue: any, matchMode: string) {
        const value = this.filter.join
            ? { prefix: this.filter.join, value: inputValue }
            : inputValue;
        const field = this.filter.field;
        this.table.filters[field] = { value, matchMode };
    }
}
