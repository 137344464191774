import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

@Injectable()
export class ReceiptService {

    constructor(private api: ApiService) {
    }

    get(query?: any) {
        return this.api.get(this.api.path + '/receipt', query);
    }

    getReceipt(receiptId: number) {
        return this.api.get(this.api.path + '/receipt/' + receiptId);
    }

    completeReceipt(data: any) {
        return this.api.post(this.api.path + '/receipt/complete', data);
    }

}
