<ng-container [ngSwitch]="true">
    <ng-container *ngSwitchCase="filter.matchMode == 'equals' || filter.matchMode == 'contains'">
        <input pInputText type="text" placeholder="{{filter.label | translate}}"
               (input)="filterTable($event.target.value,filter.matchMode)"/>
    </ng-container>
    <ng-container *ngSwitchCase="filter.matchMode == 'gt' ||  filter.matchMode == 'lt'">
        <div class="ui-inputgroup">
            <p-dropdown [options]="operators" #dropdown
                        (onChange)="filterTable(input.value,dropdown.value || 'gt')">
            </p-dropdown>
            <input pInputText type="text" placeholder="{{filter.label | translate}}" #input
                   style="width: 100%"
                   (input)="filterTable(input.value,dropdown.value || 'gt')"/>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="filter.matchMode == 'multi'">
        <ng-select
            class="select-custom"
            [items]="data"
            [dropdownPosition]="'bottom'"
            [multiple]="true"
            bindLabel="label"
            [closeOnSelect]="false"
            bindValue="value"
            [notFoundText]="'Gösterilecek data yok' | translate"
            (change)="selectChange($event)"
            (clear)="clearSelect($event)"
            [clearSearchOnAdd]="true" placeholder="{{filter.label | translate}}"
            [(ngModel)]="multiSelected">
            <ng-template ng-header-tmp>
                <p-checkbox (click)="toggleSelectAll()" binary="true"></p-checkbox>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <ng-container *ngIf="items.length > 2">
                    <div class="ng-value">
                        <span class="ng-value-label">{{items.length}} {{'seçili...' | translate}}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="items.length <= 2">
                    <div class="ng-value" *ngFor="let item of (items ? items.slice(0,2): [])">
                        <span class="ng-value-label">{{item.label | translate}}</span>
                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </div>
                </ng-container>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <p-checkbox [ngModel]="item$.selected" binary="true" id="item-{{index}}"></p-checkbox>
                {{item.label | translate}}
            </ng-template>
        </ng-select>

    </ng-container>
    <ng-container *ngSwitchCase="filter.matchMode == 'single'">
        <ng-select
            class="select-custom"
            [dropdownPosition]="'bottom'"
            [items]="data"
            bindLabel="label"
            bindValue="value"
            [notFoundText]="'Gösterilecek data yok' | translate"
            (change)="filterTable($event?.value,'equals')"
            placeholder="{{filter.label | translate}}"
            [(ngModel)]="multiSelected">
            <ng-template ng-label-tmp let-item="item">
                <span class="ng-value-label">{{item.label | translate}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                {{item.label | translate}}
            </ng-template>
        </ng-select>
    </ng-container>
    <ng-container *ngSwitchCase="filter.matchMode == 'dateRange'">
        <p-calendar selectionMode="range" readonlyInput="true"
                    placeholder="{{filter.label | translate}}"
                    [style.width.%]="100"
                    dateFormat="yy-mm-dd" [monthNavigator]="true" [showButtonBar]="true" [yearNavigator]="true"
                    [showIcon]="true"
                    [yearRange]="yearRange"
                    [(ngModel)]="rangeDates"
                    [selectOtherMonths]="true"
                    [maxDate]="maxDate"
                    dataType="string"
                    (onSelect)="filterTable(rangeDates,'dateRange')"
                    (onClearClick)="filterTable('','dateRange')"></p-calendar>
    </ng-container>
    <ng-container *ngSwitchCase="filter.matchMode == 'refresh'">
        <button pButton [icon]="'fas fa-fw fa-sync-alt'" [iconPos]="'left'" (click)="table.filter(null,null,null)"></button>
    </ng-container>
</ng-container>
