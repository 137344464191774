

 <div class="">
    <div class="layout-menu-logo"><a (click)="app.menuActive = false"><span
        style="color: #fff;line-height: 60px;font-size: 20px;">BackOffice</span>
    </a>
    </div>
    <div class="layout-menu-wrapper">
        <p-scrollPanel #scrollPanel [style]="{height: '100%'}">
            <div class="menu-scroll-content">
                <div class="reseller-limit" *ngIf="app.user.userType === 0"><i class="fa fa-credit-card"></i>
                    <span style="margin-left: 6px; vertical-align: middle">{{app.user.limit | currency:'':''}}</span></div>
                <ul app-submenu [item]="model" [root]="true" class="layout-menu"></ul>
            </div>
        </p-scrollPanel>
    </div>

</div> 

