import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class PaymentGatewayService {

    constructor(private api: ApiService) { }

    getpaymentGateways(query?: any) {
        return this.api.get(this.api.path + '/paymentMethod/paymentGateway', query);
    }
    insertPaymentGateway(data: any) {
        return this.api.post(this.api.path + '/paymentMethod/paymentGateway', data);
    }

    updatePaymentGateway(paymentGatewayId: any, data: any) {
        return this.api.post(this.api.path + '/paymentMethod/paymentGateway/' + paymentGatewayId, data);
    }
}